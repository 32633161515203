<template>
<!-- purpose-check user login
     created by-deepti malik -->
  <div class="loginboxpart">
    <div class="loginform">
        <div class="loginlog">
            <img src="images/icons/gigly_logo.svg" alt="INOO2">
            <div class="cl"></div>
            <span>{{$store.state.Messages.heading}}</span>
        </div>
        <div class="cl"></div>
        <div class="login_formbox login">
            <div class="warning" v-if="sucess!==''">
            <div class="positive-error">
              <div class="crossbtn" @click="sucess=''">X</div>
              <p>{{sucess}}</p>
            </div>
          </div>
           <div class="warning" v-if="errormessage!==''">
            <div class="negative-error">
              <div class="crossbtn" @click="errormessage=''">X</div>
              <p>{{errormessage}}</p>
            </div>
          </div>
            <form @submit.prevent="checkLogin">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="text" placeholder="Enter Your User Name" v-model="email" 
                        @input="error.username ? error.username=null : '' "
                        :class="{ 'is-invalid': error.username}"
                         class="form-control"/>                               
                        <div v-if="error.username" class="invalid-feedback">{{error.username[0]}}</div>
                        <div class="cl"></div>
                    </div>
                </div>
                 <div class="col-md-12">
                    <div class="form-group">
                        <label>Password</label>
                        <input type="password" placeholder="Enter Your Password" v-model="password" 
                        @input="error.password ? error.password=null : '' "
                        :class="{ 'is-invalid': error.password}"
                         class="form-control"/>                               
                        <div v-if="error.password" class="invalid-feedback">{{error.password[0]}}</div>
                        <div class="cl"></div>
                    </div>
                </div>
                <div class="cl"></div>
                <div class="col-md-12">
                    <div class="loginbtnpart">
                        <button type="submit" class="">Continue</button>
                    </div>
                </div>
                <div class="cl"></div>
            </div>
            </form>
        </div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
export default {
  data() {
    return {
      error: "",
      sucess:"",
      errormessage:"",     
      email: "", 
      password:"",  
      //usertoken: VueCookies.get(window.location.hostname.substring(10, 4) + "_usertoken"),   
    };
  },
  methods: { 
      checkLogin(e) {
      e.preventDefault();     
      this.$store.dispatch("ajax", {
          method: "post",
          url: "api/checklogin",
          params: {
            username: this.email,
            password: this.password,
            token: this.$store.state.Login.UserData.token,            
          },
        })
        .then((response) => {        
          if (response.data.status === 1) {
          // if (response.data.data.username!= '') {
          //    this.$root.page_type = "whitelistip";
          //   }             
            this.sucess=response.data.message;
           }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.error = error.response.data.data;   
            this.errormessage=error.response.data.message;        
          }
        });      
    }
  }
};
</script>
