<!--
 * Title      : Save Vendor
 * Developer  : Gopi Boddu
 * Description: This page is designed for Vendor Saving Page
 *
 -->
<template>
<div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle"><div class="iinnoicon-reports"></div> Distribution</div>
        </div>
         <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
             <CommissionLinks />
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea memberquickbox">
                <div class="formpart">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='ReportName'}">
                                <a @click="toggleSection('ReportName')">Distribution</a>
                            </div>
                            <div class="cl"></div>
                        </div>
                    </div>
                    <div class="row" v-if="opentab=='ReportName'">

                         <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Company:<span class="required">*</span></label>
                                <div class="selectbox iinnoicon-down_arrow">
                                    <select class="form-control" v-model="companyName" tabindex="1" @change="getData(); getDistributionData()"
                                    :class="{ 'is-invalid': submitted && $v.companyName.$error || errors.agency_id}" @input="errors.agency_id?errors.agency_id='':''">
                                        <option value="">Select Company</option>
                                        <option v-for="(trans,i) in companyList" :key="i" :value="trans.agency_id">{{trans.name}}</option>
                                    </select>
                                </div>
                                <div v-if="submitted && $v.companyName.$error && !errors.agency_id" class="invalid-feedback">
                                    <span v-if="!$v.companyName.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.agency_id" class="invalid-feedback">{{ errors.agency_id[0] }}</div>
                            </div>
                        </div>

                        <div v-if="companyName!=null">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="">Transaction Type:<span class="required">*</span></label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                        <select class="form-control" v-model="runfor" @change="getCommissiontype" tabindex="1"
                                        :class="{ 'is-invalid': submitted && $v.runfor.$error || errors.runfor}" @input="errors.runfor?errors.runfor='':''">
                                            <option value="">Select Transaction</option>
                                            <option v-for="(trans,i) in transTypeList" :key="i" :value="trans">{{trans}}</option>
                                        </select>
                                    </div>
                                    <div v-if="submitted && $v.runfor.$error && !errors.runfor" class="invalid-feedback">
                                        <span v-if="!$v.runfor.required"> {{$store.state.Messages.require}}</span>
                                    </div>
                                    <div v-if="errors.runfor" class="invalid-feedback">{{ errors.runfor[0] }}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="">Month/Year:<span class="required">*</span></label>
                                    <div class="cl"></div>
                                    <Datepicker :minimumView="'month'" :maximumView="'month'" placeholder="MM/YYYY" v-model="monthyear"
                                    :format="dispString" :highlighted="state.highlighted" :disabled-dates="state.disabledDates" @input="dispDate"/>
                                    <div v-if="errors.month_year" class="invalid-feedback positionstatic">{{ errors.month_year[0] }}</div>
                                    <div v-if="submitted && $v.monthyear.$error && !errors.month_year" class="invalid-feedback">
                                        <span v-if="!$v.monthyear.required"> {{$store.state.Messages.require}}</span>
                                    </div>
                                </div>
                            </div>
                                <div class="col-md-3">
                                <div class="form-group">
                                    <label for="">Duration:<span class="required">*</span></label>
                                    <div class="cl"></div>
                                    <div class="selectbox iinnoicon-down_arrow">
                                    <select class="form-control" v-model="duration"
                                    :class="{ 'is-invalid': submitted && errors.duration }">
                                        <option value="">Select Duration</option>
                                        <option v-for="(value, name) in NBDuration" :key="name" :value="name">{{value}}</option>
                                    </select></div>

                                    <div v-if="errors.duration" class="invalid-feedback">{{ errors.duration[0] }}</div>
                                    <div v-if="submitted && $v.duration.$error && !errors.duration" class="invalid-feedback">
                                        <span v-if="!$v.duration.required"> {{$store.state.Messages.require}}</span>
                                    </div>
                                </div>
                            </div>



                            <div class="cl"></div>

                            <div class="col-md">
                                <div class="fr mr20">
                                    <button class="fl mt20 mr10" type="button" @click="saveDistribution"  tabindex="3">Submit</button>
                                    <button class="fr mt20" type="button" tabindex="4" @click="clearAll()" >Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cl"></div>
                <div class="memberquickbox mt20" v-if="csvurl!=''" >
                    <span>{{message}} -

                        <a :href="csvurl" target="_blank" download><strong class="handpointer"> Click here to download </strong></a>
                        <!-- <a class="handpointer" target="_blank"><strong @click="downloadCSV(csvurl)"> Click here to download </strong></a> -->
                    </span>
                </div>
                <div class="cl"></div>
                <div class="memberquickbox mt20"  v-if="this.displayTable ==true">
                    <div class="tablesection">
                        <div class="tablebox">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Total Transactions :</th>
                                        <th>Commission Distributed :</th>
                                        <th>Pending:</th>
                                        <th v-if="listData.totalPending > 0" class="textcenter">Verify</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="textright">{{listData.totalTransactions}}</td>
                                        <td class="textright">{{listData.totalCommDistributed}}</td>
                                        <td class="textright">{{listData.totalPending}}</td>
                                        <td v-if="listData.totalPending > 0" class="textcenter">
                                                    <a href="#" @click="verifyCommission()"   class="tooltipbox"> <i class="fas fa-check"></i> <span>Verify Commission</span></a>
                                             </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="cl"></div>

                <!-- start table -->
                <div class="mt20 pt10"  v-if="disppage == true">
                    <div class="tablesection">
                        <div class="tablebox">
                            <table>
                                <thead>
                                    <tr>
                                    <th class="width30 textcenter">No.</th>
                                    <th class="handpointer"
                                        v-for="(column , i) in columns" :key="i"
                                        :class="column.class" >
                                        <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                                        <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                        <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(list,i) in distributionData" :key="i" >
                                        <td class="width30 textcenter"> {{ perpage * (current_page - 1) + i + 1}}</td>
                                        <td class="width50 textcenter">  {{ list.from_date    }}   </td>
                                        <td class="width50 textcenter">  {{ list.to_date     }}   </td>
                                        <td class="width50 textcenter">  {{ list.run_date       }}   </td>
                                        <td class="width50 textcenter">  {{ list.transaction_type }}   </td>
                                        <td class="width50 textcenter">  {{ list.total_available }}   </td>
                                        <td class="width50 textcenter">  {{ list.total_processed }}   </td>
                                        <td class="width50 textcenter">  {{ list.total_pending }}   </td>

                                        <td class="width70 textcenter">
                                        <a href="#" @click="verifyReort(list.summary_id, list.month_year, list.duration, list.transaction_type, list.total_available,list.total_processed, list.total_pending )" class="tooltipbox"> <i class="fas fa-check-square"></i><span>Verified </span></a>
                                        </td>

                                    </tr>

                                      <tr v-if="this.noData != ''">
                                        <td colspan="9">
                                                <div class="positive-error" style="border:none">
                                                    <p>{{noData}}</p>
                                                </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="cl"></div>
                      <div class="paginationsecion" v-if="disppage">
                        <div class="cl"></div>
                        <div class="shorting">
                            <label>Show</label>
                            <div class="shorbox">
                                <div class="selectbox iinnoicon-down_arrow">
                                    <select v-model="entries" @change="showEntries()" class="form-control">
                                        <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                                    </select>
                                </div>
                            </div>
                            <span>entries</span>
                        </div>
                        <div class="paginationbox" v-if="this.total_page > 1">
                            <ul class="pagination">
                                <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                                <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"><a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a><a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                                <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- end table  -->

                <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
     <!-- verify commission Popup -->
    <div v-if="showPopup" style="position: absolute;" class="popupbox">
        <div class="exportbox memberrecharge">
        <div class="formtitle"> <div class="logo mr10"> <div class="iinnoicon-cog">  </div> </div>
            <div class="popuptitletext">Verify commission distribution</div>
            <a class="fr popupclose handpointer" @click="showPopup=false">X</a></div>
            <div class="formpart">
                <p class="textcenter" v-if="commDistrLink !== null">
                  Commission distribution error file -
                 <a :href="commDistrLink" target="_blank" download><strong class="handpointer"> Click here to download </strong></a>
                 </p>

                <p class="textcenter" v-if="commDistrmsg!== null">
                        {{ commDistrmsg }}
                 </p>
            </div>
        </div>
        <div class="overlay"></div>
    </div>

    <!--  commission verify with transaction  popup -->
    <div v-if="showVerifyPopup" style="position: absolute;" class="popupbox">

        <div class="exportbox memberrecharge">
        <div class="formtitle"> <div class="logo mr10"> <div class="iinnoicon-cog">  </div> </div>
            <div class="popuptitletext">Verify Commission Distribution</div>
            <a class="fr popupclose handpointer" @click="clearverifyData()">X</a></div>
            <div class="formpart">

                  <!-- step - 1  -->
                <div v-if="verifystep1">
                <div class="tableloader">
                      <img src="/images/icons/logo2.svg">
                </div>
                <p class="text-center verifyText">Please Wait....  We are verifying commission data..</p>
                </div>

                 <!-- step - 2 -->
                <div  v-if="verifystep2">
                     <p class="text-center verifyText"> Total No. of commission are Available: {{verifyTotalrecord}} </p>

                <div class="text-center">
                   <p class="verifyText">
                         <i class="far fa-check-circle"></i>  Commission report verification is completed.
                     </p>
                </div>
                </div>

                 <!-- step - 3 -->
                 <div v-if="verifystep3">
                <div class="text-left">
                     <p class="verifyText"> Verify Report Summary: </p>
                      <div class="tablesection">
                        <div class="tablebox">
                      <table>
                        <tbody>
                            <tr>
                                <td class="text=left">Total Transaction</td>
                                <td>{{verifyTotalrecord}}</td>
                            </tr>

                             <tr>
                                <td class="text=left">Total Distributed Commission</td>
                                <td>{{verifyTotalDistr}}</td>
                            </tr>

                             <tr>
                                <td class="text=left">Total Pending</td>
                                <td>{{verifyTotalPeding}}</td>
                            </tr>

                            <tr>
                                <td class="text=left">Expected Amount </td>
                                <td>{{expectedAmt}}</td>
                            </tr>


                            <tr>
                                <td class="text=left">Actual Amount</td>
                                <td>{{totalAmt}}</td>
                            </tr>

                        </tbody>
                      </table>
                        </div>
                      </div>
                </div>

                <div class="cl"></div>
                 <p class="fl cancelbtn" v-if="verifylink !=''">
                    <strong> <a  :href="verifylink" download class="handpointer">Click here to download error file </a>     </strong>
                </p>
                    <div class="fr">
                        <button class="fl cancelbtn mr10" type="button" tabindex="2" @click="clearverifyData()" >Close</button>
                    </div>
                </div>


                <!-- old code
                 <p class="textcenter" v-if="verifymsg!== null">
                        {{ verifymsg }}
                 </p>
                <div class="verifylink" v-if="verifylink !=''">
                    Download File -
                    <a :href="verifylink" target="_blank" download><strong class="handpointer"> Click here to download </strong></a>
                    </div> -->
            </div>
        </div>
        <div class="overlay"></div>
    </div>


</div>
</template>
<script>
import CommissionLinks from "../CommissionLinks.vue";
import { required } from "vuelidate/lib/validators";
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
export default {
    components: {
        Datepicker,
        CommissionLinks
    },
    data() {
        return {
            opentab     : "ReportName",
            errors      : "",
            submitted   : "",
            runfor      : "",
            monthyear   : "",
            duration    : "",
            csvurl      : "",
            dispString  : "",
            message     : "",
            companyName : "",
            noData      : "",
            listData    : [],
            transTypeList: [],
            companyList : [],
            dataList    : [],
            NBDuration:[],
            RWDuration:[],
            distributionData:[],
            entries     : "",
            total_page  : 0,
            current_page: 1,
            perpage     : 0,
            disppage    : false,
            displayTable : false,
            showPopup   : false,
            showVerifyPopup: false,
            verifystep1  : false,
            verifystep2  : false,
            verifystep3  : false,
            verifyTotalrecord:0,
            verifyTotalDistr:0,
            verifyTotalPeding:0,
            expectedAmt:0,
            totalAmt:0,
            verifylink   : '',
            verifymsg    : '',
            commDistrmsg : '',
            commDistrLink : '',
            state         : {
                disabledDates: {
                    to: new Date(2021, 4, 1),
                    from: new Date()
                }
            },
             columns: [
                { text: "From Date"   , value: "from_date"   , class:"textcenter" },
                { text: "To Date"    , value: "to_date"    , class:"textcenter"},
                { text: "Generated Date"      , value: "generatd_date"      , class:"textcenter"},
                { text: "Transaction Type", value: "transaction_type", class:"textcenter"},
                { text: "Total Available", value: "total_available", class:"textcenter"},
                { text: "Total Distributed", value: "total_proceed", class:"textcenter"},
                { text: "Total Pending", value: "total_pending", class:"textcenter"},
                { text: "Verify", value: "verify_with_qa", class:"textcenter"},
            ],
        }
    },
    mounted(){
      this.getCompanyList();

    },
    validations: {
        companyName: { required },
        runfor     : { required },
        monthyear  : { required },
        duration   : { required },
    },
    methods: {
        /* Accordion  */
        toggleSection(tab) {
            if(this.opentab==tab){
                this.opentab='';
            }
            else{
                this.opentab=tab;
            }
        },

        /*Date Picker Date display*/
        dispDate()
        {
            if(this.errors.month_year)
                this.errors.month_year='';
            var year  = moment(this.monthyear).format("YYYY");
            var month = moment(this.monthyear).format("MM");
            this.dispString = month+"/"+year;
        },


        /* Get Company List */
        getCompanyList()
        {
            this.companyList    = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "section",
                url     : "api/getcompanylist"
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.companyList = response.data.data;
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },

        /* Get Data */
        getData()
        {
            this.displayTable = false;
            this.runfor      = "";
            this.monthyear   = "";
            this.duration    = "";
            this.dataList    = [];
            this.NBDuration  =[];
            this.transTypeList = [];
            this.csvurl      = "";
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "section",
                url     : "api/getcommdistroption/"+this.companyName
            })
            .then((response) => {
                if(response.data.data)
                {
                   var keys = [];
                  //  commission transaction dropdown list
                   for (var k in response.data.data.distribution_type) keys.push(k);
                   this.transTypeList = keys;

                    this.dataList = response.data.data;

                }
                else{
                    this.NBDuration = [];
                    this.RWDuration = [];
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },

        getCommissiontype()
        {
            this.NBDuration = [];

            var ab = this.dataList;
            var rn = this.runfor;

            var x = ab.distribution_type[rn];
            this.NBDuration = x;
        },

        /*Save Distribution */
        saveDistribution()
        {
            this.submitted  = true;
            this.errors     = [];
            var  monyear    = "";
            if(this.monthyear)
            {
                var year    = moment(this.monthyear).format("YYYY");
                var month   = moment(this.monthyear).format("MM");
                    monyear = month+"-"+year;
            }
            else
                monyear     = "";
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch("getajax", {
                method  : "get",
                url     :  "api/distributeCommission",
                loader  : "page",
                params  :  {
                    agency_id   : this.companyName,
                    runfor      : this.runfor,
                    month_year  : monyear,
                    duration    : this.duration,
                }
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.displayTable = true;

                    this.listData = response.data.data;
                    this.csvurl  = response.data.data.missingContract;
                    this.message = response.data.message;
                  //  this.noData  = '';
                  this.getDistributionData();
                 }
                else{

                    this.noData  = response.data.message;
                    this.csvurl  = "";
                    this.message = "";
                }
            })
            .catch((error) => {
                if (error.response.data.data) {
                    this.errors = error.response.data.data;
                    this.csvurl  = "";
                    this.message = "";
                }
            });
        },

        /* Comm Clear */
        clearComm()
        {
            if(this.errors.duration)
                this.errors.duration='';
            // this.commissionData = null;
        },
        /* Date Format Changing  */
        backendFormat (date) {
            return moment(date).format("MM-DD-YYYY");
        },
        clearAll()
        {
            this.submitted    = false;
            this.runfor       = "";
            this.monthyear    = "";
            this.duration     = "";
            this.errors       = "";
            this.csvurl       = "";
            this.message      = "";
            this.companyName  = "";
            this.listData     = [];
            this.transTypeList = [];
            this.commDistrmsg = "";
            this.commDistrLink = "";
            this.distributionData = [];
            this.NBDuration = [];
            this.disppage       = false;
        },

        // verify distribution commission
        verifyCommission(){

             var  createmonthyear    = "";
            if(this.monthyear)
            {
                var year    = moment(this.monthyear).format("YYYY");
                var month   = moment(this.monthyear).format("MM");
                    createmonthyear = month+"-"+year;
            }
            else
                createmonthyear     = "";

             this.$store.dispatch("ajax", {
                method  : "post",
                url     :  "api/verifydistributecommission",
                loader  : "page",
                params  :  {
                    agency_id   : this.companyName,
                    runfor      : this.runfor,
                    month_year  : createmonthyear,
                    duration    : this.duration,
                }
            })
            .then((response) => {
                if(response.data.data)
                {
                     this.commDistrLink = response.data.data.URL;
                     this.showPopup  = true;
                }
            })
            .catch((error) => {
                this.commDistrmsg = error.response.data.message;
                this.showPopup  = true;
                this.errors = error.response.data.data;
            })

        },

        // get distribution data
        getDistributionData()
        {
            this.disppage       = false;

            this.distributionData=[];
            this.$store.dispatch("ajax", {
                method  : "get",
                url     : "api/getcommdistrsummary/"+this.companyName,
                loader  : "section",
            })
            .then((response) => {
                if (response.data.data)
                {


                    this.distributionData     = response.data.data.data;
                    this.entries        = response.data.data.per_page;
                    this.total_page     = response.data.data.last_page;
                    this.perpage        = response.data.data.per_page;
                    this.noData         = '';
                    this.disppage       = true;
                }
                else
                {
                   // console.warn(response.data.message);

                    this.distributionData     = [];
                    this.total_page     = 0;
                    this.disppage       = true;
                    this.noData         = response.data.message;
                }

            })
            .catch((error) =>
            {
                this.distributionData     = [];
                this.total_page     = 0;
                this.disppage       = true;
                this.noData         = error.response.data.message;
            })
        },
        //pagination
        pagination(next_page)
        {
            if (this.$route.query.sort_type)
            {
                var sort_type = this.$route.query.sort_type;
                var sort_by = this.$route.query.sort_by;
                this.$router.push({
                    query: { ...this.$route.query, sort_type: "", sort_by: "" },
                });
                this.$router.push({
                    query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
                });
            }
            this.current_page = next_page;
            this.$router.push({ query: { ...this.$route.query, page: next_page } });
        },

        //show entries data
        showEntries()
        {
            if(this.entries<=100)
            {
                this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } });
            }
        },

        // verify report
        verifyReort(summaryId,monthYear,duration,transaction_type, totalRec, distRec, pendingRec)
        {
            this.showVerifyPopup = true;
            this.verifystep1 = true;

            this.$store.dispatch("ajax",
            {
                method  : "post",
                url     : "api/verifydistributecommission",
                params  : {
                    summary_id : summaryId,
                    agency_id  : this.companyName,
                    month_year : monthYear,
                    duration   : duration,
                    runfor     : transaction_type,

                }
            })
            .then((response) =>
            {
                this.verifyTotalrecord  = totalRec;
                this.verifyTotalDistr   = distRec;
                this.verifyTotalPeding  = pendingRec;

                    setTimeout(function () {
                         this.verifystep1 = false;
                         this.verifystep2 = true;
                     }.bind(this), 2000)

                     setTimeout(function () {
                           this.verifystep2 = false;
                           this.verifystep3 = true;
                     }.bind(this), 3500)


                 //this.verifymsg = response.data.message;
                if (response.data.status == 1)
                {
                    this.verifylink =  response.data.data.URL
                    this.expectedAmt = response.data.data.total_expected_amt;
                    this.totalAmt = response.data.data.total_actual_amt;
                }

                if (response.data.status == 0)
                {
                     this.verifymsg =  response.data.message;
                }


                })
                .catch((error) => {
                if (error.response.data.status == 0) {
                     this.verifymsg = error.response.data.data;
                }
            });
        },

        clearverifyData()
        {
            this.showVerifyPopup = false;
            this.verifystep1 = false;
            this.verifystep2 = false;
            this.verifystep3 = false;
            this.verifyTotalrecord=0;
            this.verifyTotalDistr=0;
            this.verifyTotalPeding=0;
            this.expectedAmt = 0;
            this.totalAmt = 0;
            this.verifylink = '';

        },
        currentDate() {
        const current = new Date();
        const date = `${current.getMonth()+1}/${current.getDate()}/${current.getFullYear()}`;
        return date;
        },

        /* Get Transaction type List - not used.  */
        getTransactionTypeList()
        {
            this.transTypeList    = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "section",
                url     : "api/gettranstypelist"
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.transTypeList = response.data.data;
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },




    }

}
</script>
<style scoped>
.popupbox .logo {
    height: auto;
    padding: 5px 0;
}

.popupbox .logo div {
    line-height: 30px;
}

.popuptitletext {
    line-height: 40px;
}
.verifylink{
     text-align: center;
}

.verifyText{
    font-size: 15px;
    font-weight: 700;
}

.exportbox{
    top:10%
}

.cancelbtn
{
    margin-top:20px;
}

</style>
