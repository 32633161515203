<template>
<div>
  <div class="deletebox" v-if="this.$store.state.ajax.errormessage">
    <div class="deleteimg"><span>!</span></div>
      <div class="cl"></div>
      <div class="textboxsection"><p>{{this.$store.state.ajax.errormessage}}</p>
        <a><button type="button" @click.prevent="close()">Close</button></a>
      </div>
    </div>
    <div class="overlay"></div>
</div>
</template>
<script>
export default {
  methods: { 
    autoHide()
    {
      setTimeout(() => this.$store.state.ajax.errormessage = "", 5000);
    },
    close(){
      this.$store.state.ajax.errormessage=''
    }
  },
  mounted(){
    this.autoHide();
  }
}
</script>
