<template>
    <div>
         <div class="col-md-4 col-sm-6">
            <div class="form-group">
                <label for="">State Group :<span class="required">*</span></label>
                <div class="selectbox iinnoicon-down_arrow">
                    <select class="form-control" v-model="stateID" @change="getStateGroupId($event)"
                    :class="{ 'is-invalid': submitted && $v.stateID.$error || errors.state_group_id}">
                        <option  value="">Select Group</option>
                        <option v-for="(list ,i ) in StageGroupList" :key="i" :value="list.state_group_id">{{list.group_name}}</option>
                    </select>
                </div>
                <div v-if="submitted && $v.stateID.$error && !errors.state_group_id" class="invalid-feedback">
                <span v-if="!$v.stateID.required"> {{$store.state.Messages.require}}</span>
                </div>
            <div v-if="errors.state_group_id" class="invalid-feedback">{{errors.state_group_id[0]}}</div>
            </div>
        </div>
        <div class="col-md-4 col-sm-6">
            <div class="form-group">
                <label for="">Rates Based On :<span class="required">*</span></label>
                <div class="selectbox iinnoicon-down_arrow">
                    <select class="form-control" v-model="selectPlan" @change="selectPlanType($event)"
                        :class="{ 'is-invalid': submitted && $v.selectPlan.$error || errors.rate_type}">
                            <option value="">Select Plan Type</option>
                      <option v-for="(list, i) in PlanList" :key="i" :value="i">{{list}}</option>
                   </select>
                </div>
                <div v-if="submitted && $v.selectPlan.$error && !errors.rate_type" class="invalid-feedback">
                <span v-if="!$v.selectPlan.required"> {{$store.state.Messages.require}}</span>
                </div>
                <div v-if="errors.rate_type" class="invalid-feedback">{{errors.rate_type[0]}}</div>
            </div>
        </div>
        <div class="cl"></div>
        <div class="row" v-if="this.PlanLevelList">
            <div class="col-md-12" >
                <p class="setuphed" >Rates :<span class="required">*</span></p>
                <div class="col-md-3 col-sm-3" v-for="(list, j) in PlanLevelList" :key="j">
                    <div class="form-group">
                        <label for="">{{list.display_name}} :</label>
                        <input type="text" tabindex="" placeholder=""  maxlength="50" v-model="rates[j]"
                        onkeypress="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                        :class="{ 'is-invalid': errors['rate.'+j]}" class="form-control"
                        @change="errors['rate.'+j]?errors['rate.'+j]='':''">
                     <!-- <div v-if="submitted && $v.firstReserve.$error && !errors['first_year_reserve.'+j]" class="invalid-feedback">
                     <span v-if="!$v.firstReserve.required"> {{$store.state.Messages.require}}</span>
                    </div> -->
                    <div v-if="errors['rate.'+j]" class="invalid-feedback">{{errors['rate.'+j][0]}}</div>
                    </div>
                </div>
                </div>
                <div class="col-md-12">
                <p class="setuphed" >Reserve Rates :<span class="required">*</span></p>
                <div class="col-md-3 col-sm-3" v-for="(list, i) in PlanLevelList" :key="i">
                    <div class="form-group">
                        <label for="">{{list.display_name}} :</label>
                        <input type="text" tabindex="" v-model="form.display_id[i]" placeholder=""
                        onkeypress="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                        @change="errors['first_year_reserve.'+i]?errors['first_year_reserve.'+i]='':''"
                        :class="{ 'is-invalid':  errors['first_year_reserve.'+i]}" maxlength="50" class="form-control">
                    <div v-if="errors['first_year_reserve.'+i]" class="invalid-feedback">{{errors['first_year_reserve.'+i][0]}}</div>
                    </div>
                </div>
              </div>
              <div class="col-md-12">
                <p class="setuphed" >Renewal Reserve Rates :<span class="required">*</span></p>
                <div class="col-md-3 col-sm-3" v-for="(list, k) in PlanLevelList" :key="k">
                    <div class="form-group">
                        <label for="">{{list.display_name}} :</label>
                        <input type="text" tabindex="" v-model="Renewal.display_id[k]" placeholder=""
                        onkeypress="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                        @change="errors['renewal_year_reserve.'+k]?errors['renewal_year_reserve.'+k]='':''"
                        :class="{ 'is-invalid':  errors['renewal_year_reserve.'+k]}" maxlength="50" class="form-control">
                    <div v-if="errors['renewal_year_reserve.'+k]" class="invalid-feedback">{{errors['renewal_year_reserve.'+k][0]}}</div>
                    </div>
                </div>
                </div>
      </div>
       <div v-if="this.noData!=''">
        <div class="warning">
            <div class="positive-error" style="border:none">
                <p>{{noData}}</p>
            </div>
        </div>
    </div>
     <div class="cl"></div>
          <div class="fr">
              <button class="fl mt20 mr10" type="button" tabindex="7" @click="AddCommission">Save</button>
              <button class="fr mt20" type="button" tabindex="8" @click="$router.push('/commission/setupcommissionlist')">Cancel</button>
          </div>
    </div>
</template>
<script>

import { required } from "vuelidate/lib/validators";

export default {
 data(){
  return{
     DistributionList:[],
     StageGroupList:[],
     BaserateList:[],
     productList:[],
     feeList:[],
     selectPlan:"",
     PlanList:"",
     Percentage:false,
     PercentageFlat:false,
     noData:"",
     errors:{},
     stateID:"",
     pID:"",
     fID:"",
     distributionBased:"",
     rateBase:"",
     rate:"",
     rateType:"",
     firstReserve:[],
     renewalReserve:[],
     PlanLevelList:[],
     selectedID:[],
     form: {
          display_id: []
        },
      Renewal:{
        display_id: []
      },
    rates:[],
    arrayrenewal:[],
    arrayrates:[],
    arrayrenewalYear:[],
    submitted:false,
    errorsmsg:"",
    }
   },
    validations: {
        stateID:{required},
        selectPlan      : { required },
       // firstReserve:{ required },
       // rates:{ required },

       // renewalReserve:{ required },

    },
    props:{
      productID:String,
     // errors:Object,
      updateCommissionData:Object,
      //submitted:Boolean,
      feeID:String,
      Dsection:String
    },
   methods:{
     getCommissionData(){
      //  this.productID=this.updateCommissionData.product_id;
      //  this.feeID= this.updateCommissionData.fees_id;
       //console.log( this.feeID)
       this.selectPlan=this.updateCommissionData.rate_type;
       this.stateID=this.updateCommissionData.state_group_id;
       this.rates=this.updateCommissionData.rate.split(",");
       this.Renewal.display_id=this.updateCommissionData.renewal_year_reserve_rate.split(",");
       this.form.display_id=this.updateCommissionData.first_year_reserve_rate.split(",");
      //  this.selectedID=this.updateCommissionData.rate_base.split(",");
      //  this.PlanLevelList=this.updateCommissionData.rate_base.split(",");
      this.selectPlan = this.updateCommissionData.rate_type;
      this.getPlanlevel();
     },
    selectPlanType(event){
     this.selectPlan=event.target.value;

     this.getPlanlevel();
    },
    getDistributionList() {
       this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/getdistributionlist",
          loader:'section'
        })
        .then((response) => {
          if (response.data.status === 1) {
             this.DistributionList=response.data.data;
            }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.errors=error.response.data.data
           }
        });
    },
    getStageGroupList() {
       if(this.$route.query.comm_id){
         this.pID=this.productID
      }
      else{
          this.pID=this.$route.query.product_id
      }
       this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/getstategroup/" + this.pID,
          loader:'section'
        })
        .then((response) => {
          if (response.data.status === 1) {
             this.StageGroupList=response.data.data;
            if(this.$route.query.comm_id)
            this.stateID=this.updateCommissionData.state_group_id;
            }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.errorsmsg=error.response.data.data
           }
        });
    },
    getPlanlevel() {
        if(this.$route.query.comm_id){
         this.pID=this.productID;
         this.fID=this.feeID;
      }
      else{
          this.pID=this.$route.query.product_id
          this.fID=this.$route.query.fee_id;
      }

       this.$store
        .dispatch("getajax", {
          method: "get",
      //   url: `api/getplan?type_name=${this.selectPlan}&product_id=${this.pID}&fee_id=${this.fID}`,
          url: `api/getplan?type_name=${this.selectPlan}&product_id=${this.pID}&fee_id=${this.fID}&state_group_id=${this.stateID}`,
          loader:'section'
        })
        .then((response) => {
          if (response.data.status === 1) {
            if(response.data.data){
              var i;
              var o;
             this.PlanLevelList=response.data.data;
             this.noData='';
             for(i in this.PlanLevelList){
                this.rates[i]=this.PlanLevelList[i].rate;
             }
            for (o = 0; o < this.PlanLevelList.length; o++) {
             this.selectedID.push(this.PlanLevelList[o].display_id);

            }
            }
            else{
              this.noData=response.data.message;
              this.PlanLevelList='';
            }
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.errorsmsg=error.response.data.data
           }
        });
    },
    getPlanList() {
       this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/getratebaselist ",
          loader:'section'
        })
        .then((response) => {
          if (response.data.status === 1) {
             this.PlanList=response.data.data;
             if(this.$route.query.comm_id)
              this.selectPlan=this.updateCommissionData.rate_type;
            }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.errorsmsg=error.response.data.data
           }
        });
    },
    getStateGroupId(event){

       console.log('state group id' +  event.target.value);

       if(this.errors.state_group_id)
        this.errors.state_group_id='';

      if(event.target.value != ""){
         this.stateID=parseInt(event.target.value);
      }

      if(event.target.value == ""){
            this.PlanLevelList = '';
            this.stateID ="";
      }

     //  this.stateID=parseInt(event.target.value);

     if(this.stateID != "" && this.selectPlan!="")
     {
         this.getPlanlevel();
     }



        // if(this.$route.query.state_group_id!=''){
        //  this.$router.push({ query: { ...this.$route.query,  state_group_id:this.stateID } });
        //  }


    },
    // updatedInfo() {
    //         this.submitted==true
    //        //this.errors    = [];
    //         // this.$v.$touch();
    //         // if (this.$v.$invalid) {
    //         //    this.submitted = true;
    //         //     return;
    //         // }
    //         this.$emit('clicked', this.stateID,this.selectPlan,this.rates, this.form, this.selectedID, this.submitted);
    //     },
        AddCommission(){
          this.arrayrenewal=[];
          this.arrayrenewalYear=[];
          this.arrayrates=[];
        var i;
        for(i in this.form){
         this.arrayrenewal.push(this.form[i]);
        }
        var k;
        for(k in this.Renewal){
         this.arrayrenewalYear.push(this.Renewal[k]);
        }
       var j=0;
        for(j=0;j<this.rates.length;j++){
         this.arrayrates.push(this.rates[j]);
        }
       console.log(this.arrayrenewalYear[0].join(","))
       var url="";
       var method     = "";

       if(this.$route.query.comm_id)

        {
            this.pID=this.productID;
            this.fID=this.feeID;
            method     = "put";
            url        = "api/accountcommission/"+this.$route.query.comm_id;
        }
        else
        {
            this.pID=this.$route.query.product_id
            this.fID=this.$route.query.fee_id;
            method     = "post";
            url        = "api/accountcommission"
            }
        this.submitted = true;
        this.$v.$touch();
            if (this.$v.$invalid) {
                return;
        }
       this.$store
        .dispatch("ajax", {
          method   :method,
          url      :url,
          params:{
              product_id:this.pID,
              fee_id:this.fID,
              state_group_id :this.stateID,
              distribution_based:this.Dsection ,
              rate_base :this.selectedID.join(","),
              rate:this.arrayrates.join(","),
              rate_type:this.selectPlan,
              first_year_reserve :this.arrayrenewal[0].join(","),
              renewal_year_reserve:this.arrayrenewalYear[0].join(","),
            }
        })
        .then((response) => {
          if (response.data.status === 1) {
               this.$store.state.success_message=response.data.message;
                if(this.$route.query.comm_id){
                this.getCommissionData();
              }
                this.errors={};
               this.submitted=false;
               this.$store.state.Messages.Perrors='';

               if(method == 'put'){
                  location.reload();
               }
            }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.errors=error.response.data.data;
           this.$store.state.Messages.Perrors=error.response.data.data;
           }
        });
    },

    },
  //  watch:{
  //    'submitted': {
  //     handler: function () {
  //       if(this.submitted==true)
  //         this.$v.$touch();
  //           if (this.$v.$invalid) {
  //               return;
  //       }
  //     }
  //   }

  //  },
   mounted(){
      //  if(this.submitted==true){
      //    this.$v.$touch();
      //       if (this.$v.$invalid) {
      //           return;
      //   }
      //  }
       this.getDistributionList();
       this.getPlanList();
      if(this.$route.query.comm_id){
         this.getCommissionData();
      }
       if(this.$route.query.product_id!=null||this.productID!=null){
            this.getStageGroupList()
       }
   }
}
</script>