<template>
  <div id="app">
    <div class="pageheightfooter" :class="{'pb-0':this.$route.path=='/'}">
    <TopHeader v-if="this.$route.name != 'userlogin'"/>
    <div class="agfront-conent">
    <Navbar v-if="this.$route.name != 'userlogin' "/>    
    <router-view :key="$route.fullPath"></router-view>
    </div>
    <Footer v-if="this.$route.name != 'userlogin'"/>     
    </div>
    <div class="pageloader" v-show="this.$store.state.ajax.pageloader==true">
      <img src="/images/icons/logo2.svg">
    </div>
    <div class="sectionloader" v-show="this.$store.state.ajax.sectionloader==true">
      <img src="/images/icons/logo2.svg">
    </div>
    <success  v-if="this.$store.state.success_message!=''&&this.$store.state.success_message!=null&&this.$store.state.success_message!=undefined"/>
    <error  v-if="this.$store.state.error_message!=''&&this.$store.state.error_message!=null&&this.$store.state.error_message!=undefined"/>
    <permission  v-if="this.$store.state.ajax.errormessage!=''&&this.$store.state.ajax.errormessage!=null&&this.$store.state.ajax.errormessage!=undefined"/>
    <deletepopup v-if="this.$store.state.deletePopup==true"/>
  </div>
</template>
<script>
import TopHeader from '@/components/Header/TopHeader.vue'
import Navbar from '@/components/LeftNavigation/Navbar.vue'
import Footer from '@/components/Footer/Footer.vue'
import success from '@/components/AlertMessags/success.vue'
import deletepopup from '@/components/AlertMessags/deletepopup.vue'
import error from '@/components/AlertMessags/error.vue'
import permission from '@/components/AlertMessags/permission.vue'
export default {
  components: {   
    TopHeader, 
    Navbar, 
    Footer,
    success,
    deletepopup,
    error,
    permission
  },
  data: () => ({
   page_type: 'username',
  }),
}
</script>
<style>
  @import '../public/css/bootstrap.css';
  @import '../public/css/incss.css';
  @import '../public/css/inmedia.css';
  @import '../public/css/pagecss.css';
  @import '../public/css/iconsfont.css';
  @import '../public/ie7/ie7.css';
  @import '../public/css/font-awesome.min.css';
  @import '../public/css/font-awesome-all.css';
</style>