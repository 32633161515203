<template>
    
<div class="pagesection">
    <div class="leftsection">
    </div>
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle"><div class="iinnoicon-sate_group"></div>{{$route.query.group_id?'Edit State Group':'Add State Group'}}</div>
            <div class="quickmenubox toprighticon">
            </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <CommissionLinks />
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
               <div class="member-quickprofilebox memberquickbox profileLinkCard">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="formpart">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="tab-formtitle iinnoicon-down_arrow activemode">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">State Group</a>
                                            </div>
                                            <div class="cl"></div>
                                        </div>
                                    </div>
                                    <div class="row maxwidth1400 collapse in" id="collapse1" aria-expanded="false">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Group Name:<span class="required">*</span></label>
                                                <input type="text" class="form-control" v-model.trim="$v.group_name.$model" tabindex="1" @input="errors.group_name ? errors.group_name='' : '' "
                                                   placeholder="Enter Group" maxlength="50" :class="{ 'is-invalid': submitted && $v.group_name.$error || errors.group_name}" @keydown="checkIsValid()">
                                                <div v-if="submitted && $v.group_name.$error && !errors.group_name" class="invalid-feedback">
                                                    <span v-if="!$v.group_name.required"> {{$store.state.Messages.require}}</span>
                                                    <span v-if="!$v.group_name.groupNameValidator">{{$store.state.Messages.alphaNum}}</span>
                                                </div>
                                                <div v-if="errors.group_name" class="invalid-feedback positionstatic">{{errors.group_name[0]}}</div>
                                                <div v-if="errors.state_id" class="invalid-feedback positionstatic">{{errors.state_id}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Product:<span class="required">*</span></label>
                                                <div class="selectbox iinnoicon-down_arrow">
                                                <select class="form-control" v-model="product_name" tabindex="2" @change="errors.product_id?errors.product_id='':''"
                                                    :class="{ 'is-invalid': submitted && $v.product_name.$error || errors.product_id}">
                                                    <option value="">Select Product</option>
                                                    <option v-for="(plist,i) in productsList" :key="i" :value="plist.id?plist.id:plist.product_id">{{plist.product_name}}</option>
                                                </select></div>
                                                <div v-if="submitted && $v.product_name.$error && !errors.product_id" class="invalid-feedback">
                                                    <span v-if="!$v.product_name.required"> {{$store.state.Messages.require}}</span>
                                                </div>
                                                <div v-if="errors.product_id" class="invalid-feedback">{{errors.product_id[0]}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">State:<span class="required">*</span></label>
                                                <div class="cl"></div>
                                                <div class="dropdown" >
                                                        <div tabindex="3"  class="form-control reasonselect maxwidth400" @click="showDropdown">
                                                            {{state_type==''?'Select State':state_type}}
                                                        <select class="form-control mselect" tabindex="3" v-model="state_type" @change="stateChange()">
                                                            <option value="">Select State</option>
                                                            <option value="all">All</option>
                                                            <option value="custom" @click="show=true">Custom</option>
                                                        </select>
                                                        <div v-if="submitted && $v.state_type.$error && !errors.state_type" class="invalid-feedback positionstatic">
                                                            <span v-if="!$v.state_type.required"> {{$store.state.Messages.require}}</span>
                                                        </div>
                                                        <div v-if="errors.state_type" class="invalid-feedback positionstatic">{{errors.state_type[0]}}</div>
                                                        
                                                        </div>
                                                        <div class="cl"></div>
                                                        <div class="mcheckbox" v-if="show2" v-click-outside="hidepopup2" tabindex="3" >
                                                        <ul >
                                                            <li v-for="(list,i) in columns" :key="i"  :value="list.value" @click="stateChange(list.value)">{{list.text}}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div v-if="show==true" class="statelistclass" v-click-outside="hidepopup">
                                                    <ul class="temp1 autocomplete-results">
                                                        <li v-for="(aState,i) in statesList" :key="i" class="nocustcheck">
                                                            <input
                                                                type="checkbox"
                                                                :id="aState.state_id"
                                                                :value="aState"  
                                                                :checked="aState.selected==true"
                                                                @change="displaycolumns(aState)"
                                                                class="mt10" />
                                                            <label :for="aState.state_id"> {{aState.state_code}}</label><br>
                                                        </li>
                                                    </ul> 
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="cl"></div>
                                        <div class="col-md-12">
                                            <div class="fr">
                                                <button class="fr mt20" type="button" tabindex="5" @click="$router.push('/commission/stategroup')">Cancel</button>
                                                <button class="fl mt20 mr10" type="button" tabindex="4" @click="saveStateGroup()">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cl"></div>
                        </div>    
                    </form>
                </div> 
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import CommissionLinks from "../CommissionLinks.vue";
import functionmixins from "../../../mixins/functionmixins.js";
import { required, helpers } from "vuelidate/lib/validators";
import vClickOutside from 'v-click-outside';
const groupNameValidator = helpers.regex('firstnameValidator', /^[A-Za-z0-9 _']*$/i);
export default({
    mixins: [functionmixins],
     components:{
       CommissionLinks
    },
    data() {
        return {
            errors          : "",
            product_name    : "",
            group_name      : "",
            state_type      : "",
            FeeList         : "",
            statename       : "",
            noData          : "",
            submitted       : "",
            search          : false,
            show            : false,
            show2           : false,
            showState       : false,
            statesList      : [],
            assignStatesList: [],
            productsList    : [],
            groupList       : [],
            columns: [   
                { text: "All",    value: "all" },
                { text: "Custom", value: "custom"   }, 
            ], 
        }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    validations: {
        product_name    : { required },
        group_name      : { required, groupNameValidator },
        state_type      : { required },
    },
    
    mounted()
    {
        this.getStatesList();
        this.getProductList();
    },
    methods: {
        getProductList() 
        {  
            this.selected=[];       
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/product", 
                loader  : "page"
            })
            .then((response) => {        
            if (response.data.data) {  
                this.productsList  = response.data.data;
                }
            })
            .catch((error) => {        
                this.errors      = error.response.data.data;
            });      
        },

        checkIsValid () {
            this.submitted = true;
        },

        /*Get State Group Data */
        getStateGroup()
        {
            this.assignStatesList = [];
            this.$store.dispatch("getajax",
            {
                method    : "get",
                url       : `api/commissionstategroup/${this.$route.query.group_id}`,
                loader    : "page",
            })
            .then((response) =>{
                if(response.data.data)
                {
                    this.product_name   = response.data.data.product_id;
                    this.group_name     = response.data.data.group_name;
                    this.state_type     = response.data.data.state_type;
                    if(this.state_type == 'custom')
                    {
                        this.assignStatesList=response.data.data.state_id.split(",");
                        if(this.assignStatesList)
                        {
                            for(var i in this.assignStatesList)
                            {
                                this.statesList.find(item => item.state_id == this.assignStatesList[i]).selected = true;
                            }
                        }
                        this.show = true;
                    }
                    else
                        this.show = false;
                }
            })
            .catch((error) =>{
                this.errors = error.response.data.data;
            })
        },
        /* Save StateGroup   */  
        saveStateGroup() 
        {
            this.submitted = true;
            this.errors    = [];
            var method     = "";
            var url        = ""; 
            var sList      = null; 
            if(this.state_type == 'custom')
                sList = this.assignStatesList.join(",");
            if(this.$route.query.group_id)
            {
                method     = "put";
                url        = "api/commissionstategroup/"+this.$route.query.group_id;
            }
            else
            {
                method     = "post";
                url        = "api/commissionstategroup" 
            }
            
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch("ajax", 
            {
                method  : method,
                url     : url,
                params  : {
                    product_id  : this.product_name,
                    group_name  : this.group_name,
                    state_type  : this.state_type,
                    state_id    : sList,      
                }
            })
            .then((response) => 
            {
                if (response.data.status == 1) {
                    this.$store.state.success_message=response.data.message;
                    this.$router.push("/commission/stategroup");
                }
                })
                .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                    // this.$store.state.error_message=this.errors.group_name;
                }
            });
        },

        /* Checkbox Click */
        displaycolumns(stateval){
            var state=stateval.state_id.toString();
            if(this.assignStatesList.includes(state) == false)
                this.assignStatesList.push(state);

            if(stateval.selected==true){
                this.statesList.find(item => item.state_id == state).selected = false;
                this.assignStatesList.splice(this.assignStatesList.indexOf(state), 1)
            }
            else
            { 
                this.statesList.find(item => item.state_id == state).selected = true;
                // this.assignStatesList.find(item => item.state_id == state).selected = true;
            }
        },
        
        /* Get States List */
        getStatesList()
        {
            this.statesList = [];
        this.$store
            .dispatch("getajax", {
            method  : "get",
            loader  : "page",
            url     : "api/state",
            })
        .then((response) => {
            this.statesList = response.data.data;
                for (var i = 0; i < this.statesList.length; i++) {
                this.$set(this.statesList[i], "selected", false);
                }
                 if(this.$route.query.group_id)
                    this.getStateGroup(); 
            })
        .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            })
        },

        /* While State type changes  */
        stateChange(event)
        {  
            if(this.errors.state_type) 
                this.errors.state_type='';
            if(this.errors.state_id) 
                this.errors.state_id='';
            
            if(event == 'custom')
            {
                
                this.show = true;
            }
            this.show2 = false;
            this.state_type = event;
        },
        hidepopup()
        {
            this.show=false;
        },
        hidepopup2()
        {

            this.show2=false;
        },
        showDropdown()
        {
            this.show2 = true;
        },
        clearAll()
        {
            this.product_name   = "";
            this.group_name     = "";
            this.state_type     = "";
        }
    }
})
</script>
<style scoped>
ul.temp1 li {
    float: left !important;
    width: 75px;
}

ul.temp1 li input {
    position: relative;
    top: -5px;
}

ul.temp1 {
    list-style: none;
}
</style>