<!--
 * Title      : whitelist 
 * Developer  : Deepti malik
 * Description: This page is designed for whitelist code
 * 
 -->
<template>
<div>
  <form v-if="this.$root.page_type =='whitelistip'">   
    <div class="loginboxpart">
      <div class="loginform">
        <div class="loginlog">
          <img src="images/icons/gigly_logo.svg" alt="gigly" />
          <div class="cl"></div>
          <span>{{$store.state.Messages.heading}}</span>
        </div>
        <div class="cl"></div>
        <div class="login_formbox forgot">
           <transition name="slide-fade">
          <div class="warning" v-if="error!==''">
          <div class="negative-error">
          <div class="crossbtn" @click="error=''">X</div>
            <p>{{error.ip[0]}}</p>
            <p>{{error.email[0]}}</p>
            <p>{{error.token[0]}}</p>
            </div>
          </div> 
           </transition>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="forgottitle">Send Security Code</label>
              </div>
            </div>
            <div class="cl"></div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="gainaccess">To Gain Access From IP {{$store.state.Login.loginData.ip_address}}</label>
                <div class="radiobox">
                  <input id="sendcode" type="radio" class="form-control" checked/>
                  <label for="sendcode">Send security code to {{emailName[0]}}<sup>*****</sup>{{emailNamee}}</label>
                  <!-- <label for="sendcode">Send security code to {{$store.state.Login.loginData.email}}<sup>***</sup>gmail.com</label> -->
                </div>
                <div class="cl"></div>
              </div>
            </div>
            <div class="cl"></div>
            <div class="col-md-12">
              <div class="loginbtnpart">
                <button type="submit" class="" @click="getwhitelistCode">Send Code</button>
                <!-- <button type="submit" class="" @click.prevent="$root.page_type = 'username'">Cancel</button> -->
              </div>
            </div>
            <div class="cl"></div>
          </div>
        </div>
      </div>
      <div class="cl"></div>
    </div>
  </form>
  <form v-if="this.$root.page_type =='whitelistipstep2'">
    <div class="loginboxpart">
      <div class="loginform">
          <div class="loginlog">
              <img src="images/icons/gigly_logo.svg" alt="gigly">
              <div class="cl"></div>
              <span>{{$store.state.Messages.heading}}</span>
          </div>
          <div class="cl"></div>
          <div class="login_formbox forgot">
          <transition name="slide-fade">         
           <div class="warning" v-if="errormessage!==''">
            <div class="negative-error">
              <div class="crossbtn" @click="errormessage=''">X</div>
              <p>{{errormessage}}</p>
            </div>
          </div> 
         
           <div class="warning" v-if="tokenmsg!==''">
            <div class="negative-error">
              <div class="crossbtn" @click="tokenmsg=''">X</div>
              <p>{{tokenmsg}}</p>
            </div>
          </div> 
          <div class="warning" v-if="resendCode!==''">
            <div class="positive-error">
              <div class="crossbtn" @click="resendCode=''">X</div>
              <p>{{resendCode}}</p>
            </div>
          </div>         
          </transition>
              <div class="row">
                  <div class="col-md-12">
                      <div class="form-group">
                          <label class="forgottitle">Send Security Code</label>
                      </div>
                  </div>
                  <div class="cl"></div>
                  <div class="col-md-12">
                      <div class="form-group">
                          <label>Please enter security code which has been sent to  {{emailName[0]}}<sup>*****</sup>{{emailNamee}}</label>
                          <input type="number" placeholder="Enter Security Code" v-model="emailkey" 
                           @input="errors.access_code ? errors.access_code=null : '' "
                          :class="{ 'is-invalid': submitted && $v.emailkey.$error || errors.access_code}"
                           class="form-control">
                           <div v-if="submitted && $v.emailkey.$error && !errors.access_code" class="invalid-feedback">{{$store.state.Messages.require}}</div>                              
                          <div v-if="errors.access_code && !$v.emailkey.$error" class="invalid-feedback">{{errors.access_code[0]}}</div>
                          <div class="cl"></div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="bottom">
                          <p class="forget-pass">
                              <a href="#" title="" @click.prevent="resendwhitelistCode">Resend Security Code</a>
                          </p>
                      </div>
                  </div>
                  <div class="cl"></div>
                  <div class="col-md-12">
                      <div class="loginbtnpart">
                          <button type="submit" class="" @click.prevent="getMailKey">Submit</button>
                          <button type="submit" class="" @click.prevent="$root.page_type = 'username'">Cancel</button>
                      </div>
                  </div>
                  <div class="cl"></div>
              </div>
          </div>
      </div>
      <div class="cl"></div>
  </div>
  </form>
  <form v-if="this.$root.page_type =='whitelistipstep3'">
    <div class="loginboxpart">
      <div class="loginform">
          <div class="loginlog">
              <img src="images/icons/gigly_logo.svg" alt="gigly">
              <div class="cl"></div>
              <span>{{$store.state.Messages.heading}}</span>
          </div>
          <div class="cl"></div>
          <div class="login_formbox forgot">
              <div class="row">
                  <div class="col-md-12">
                  </div>
                  <div class="cl"></div>
                  <div class="col-md-12">
                      <div class="form-group">
                          <label class="text-center">Your IP Address has been allowed for the access</label>
                      </div>
                  </div>
                  <div class="cl"></div>
                  <div class="col-md-12">
                      <div class="loginbtnpart">
                          <button type="submit" class="fullwidth" @click.prevent="$root.page_type='username'">Go To Login</button>
                        
                      </div>
                  </div>
                  <div class="cl"></div>
              </div>
          </div>
      </div>
      <div class="cl"></div>
    </div>
  </form>
</div>
</template>
<script>
import { required} from "vuelidate/lib/validators";
import VueCookies from 'vue-cookies'
export default {
  data() {
    return {
      error: "",  
      errors:"",
      codesucess:"",   
      email: "", 
      password:"", 
      emailkey:"",
      emailName:"",
      emailNamee:"",
      submitted: false,  
      errormessage:"",    
      resendCode:"", 
      tokenmsg:"",
      usertoken: VueCookies.get(window.location.hostname.substring(10, 4) + "_usertoken"),   
    };
  },
   validations: {
    emailkey: { required,},    
  },
  methods: {
    autoHide()
    {
    setTimeout(() => this.error = "", 5000);
    setTimeout(() => this.errormessage = "", 5000);
    setTimeout(() => this.resendCode = "", 5000);
     setTimeout(() => this.errors = "", 5000);
     setTimeout(() => this.tokenmsg = "", 5000);
    }, 
    getwhitelistCode(e) {     
      e.preventDefault();     
      this.$store.dispatch("ajax", {
          method: "post",
          url: "api/whitelistCode",
          params: {
            ip: this.$store.state.Login.loginData.ip_address,
            email: this.$store.state.Login.loginData.email,
            token: this.$store.state.Login.UserData.token,            
          },
        })
        .then((response) => {        
          if (response.data.status === 1) {
           this.$root.page_type = "whitelistipstep2";
          }
          
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.error = error.response.data.data; 
            this.autoHide();              
          }
        });      
    },
//resend security code
   resendwhitelistCode(e) {     
      e.preventDefault();     
      this.$store.dispatch("ajax", {
          method: "post",
          url: "api/whitelistCode",
          params: {
            ip: this.$store.state.Login.loginData.ip_address,
            email: this.$store.state.Login.loginData.email,
            token: this.$store.state.Login.UserData.token,            
          },
        })
        .then((response) => {        
          if (response.data.status === 1) {           
            this.resendCode= response.data.message; 
            this.autoHide();           
          }          
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.error = error.response.data.data; 
            this.autoHide();              
          }
        });      
    },

    //get mail code/key
    getMailKey(e) {     
      e.preventDefault(); 
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }     
      this.$store.dispatch("ajax", {
          method: "post",
          url: "api/registerIp",
          params: {
            access_code: this.emailkey,
            email: this.$store.state.Login.loginData.email,
            token: this.$store.state.Login.UserData.token,            
          },
        })
        .then((response) => {        
          if (response.data.status === 1) {
           this.$root.page_type = "whitelistipstep3";
          }
          this.codesucess=response.data.message;
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {         
            if (error.response.data.code==401) {
             this.errormessage=error.response.data.message;
             this.autoHide();
            } 
            else if (error.response.data.code==400) {
             this.tokenmsg=error.response.data.message;
             this.autoHide();
            }    
            if(error.response.data.data) {           
            this.errors = error.response.data.data;
            //console.log(error.response.data.data)
            this.autoHide();
            }           
          }
        });      
    }
  }, 
  mounted(){
    var email=this.$store.state.Login.loginData.email
    this.emailName  = email.substring(0, email.lastIndexOf("@"));
    this.emailNamee = email.substring(email.lastIndexOf("@") );
  }
};
</script>

