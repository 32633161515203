<template>
<div>
  <div class="successfullbox" v-if="this.$store.state.success_message">
    <div class="imgbox">
      <!-- <img src="/images/icons/successfullyicon.svg"> -->
      <div class="iinnoicon-successfully_done"></div>
    </div>
    <div class="cl"></div>
    <div class="textboxsection">
      <p>{{this.$store.state.success_message}}</p>
      <button type="button" @click="$store.state.success_message=''">Close</button>
    </div>
  </div>
   <div class="deletebox" v-if="this.$store.state.error_message">
    <div class="deleteimg"><span>!</span></div>
      <div class="cl"></div>
      <div class="textboxsection"><p>{{this.$store.state.error_message}}</p>
        <a><button type="button" @click="$store.state.error_message=''">Close</button></a>
      </div>
    </div>
  <div class="overlay"></div>
</div>
</template>
<script>
export default {
  methods: { 
    autoHide()
    {
      setTimeout(() => this.$store.state.success_message = "", 5000);
    },
  },
  mounted(){
    this.autoHide();
  }
}
</script>
