<!--
 * Title      : State Group List Page
 * Developer  : Gopi Boddu
 * Description: This page is designed for State Group List Page
 *
 -->
<template>
<div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle"><div class="iinnoicon-sate_group"></div>State Group List</div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <li class="tooltipbox"><router-link to="/commission/addstategroup" title="">
                        <div class="iinnoicon-add_state_group"></div>
                    </router-link><span class="tooltips160">Add State Group</span></li>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <CommissionLinks />
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="tablesection">
                    <div class="tablebox">
                        <table>
                            <thead>
                                <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                                    <th class="width20 textcenter">
                                    <div class="table-checkbox">
                                    <input type="checkbox"  v-model="selectAll" @click="select" id="selectAll" v-if="active == true || sHead == true" />  
                                    </div>
                                    </th>
                                    <th class="width30 textcenter">No.</th> 
                                    <th class="handpointer" 
                                    v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                                    :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-agent'" >
                                    <span>{{column.text}}</span>
                                    <span v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'" class="tabledesc"></span>
                                    <span v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" class="tableasc"></span>
                                </th>   
                                <th class="width30 textcenter">Action</th>                                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(list,i) in StateList" :key="i"
                                @mouseover="selectItem(list)" @mouseleave="unSelectItem(list)" v-bind:class="{ 'trselected': selItem[list.id] === true || list === selectedItem}">
                                    <td class="width20 textcenter">
                                        <div class="table-checkbox">
                                            <input type="checkbox" :value="list.id" :name="list.id" v-model="selected" v-if="list === selectedItem || selItem[list.id] == true" @change="checkSelected($event)"/>                                       
                                        </div>
                                    </td>   
                                    <td class="width30 textcenter">{{perpage * (current_page - 1) + i + 1}}</td>
                                    <td class="width100 textleft">{{list.product_name}}</td>
                                    <td class="width100 textleft">{{list.group_name}}</td>
                                    <td class="width400 textleft">{{list.state_type=='all'?'All':list.state_code}}</td> 
                                    <td class="width30 textcenter">
                                    <div class="addvancecommisionicons">
                                        <div class="iinnoicon-edit handpointer" @click="editStateGroup(list.id)"></div>
                                    </div>
                                    </td>
                                </tr> 
                                <tr v-if="this.noData != ''">
                                <td colspan="15">                     
                                    <div class="warning">
                                    <div class="positive-error" style="border:none">                         
                                        <p>{{noData}}</p>
                                    </div>
                                    </div>
                                </td>
                                </tr>                         
                            </tbody>
                        </table> 
                    <div class="cl"></div>
                    </div>
                <div class="cl"></div>
                </div>
                <div class="cl"></div>
                <div class="paginationsecion" v-if="disppage">
                    <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div>
                    <div class="cl"></div>
                    <div class="shorting">
                    <label>Show</label>
                    <div class="shorbox">
                    <div class="selectbox iinnoicon-down_arrow">
                    <select v-model="entries" @change="showEntries()" class="form-control"> 
                    <!-- <option value="">select</option>  -->
                    <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                    </select></div>
                    </div>
                    <span>entries</span>
                    </div>
                    <div class="paginationbox" v-if="this.total_page > 1">
                        <ul class="pagination">
                            <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                            <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"><a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a><a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                            <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                        </ul>
                    </div>
                </div>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
         <!-- //delete Popup -->
        <div v-if="deleteData">
        <div class="deletebox">
            <div class="deleteimg"><span>!</span></div>
                <div class="cl"></div>
                <div class="textboxsection">
                <p>{{text}}</p>
                <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="yesbtn">Delete</button></a>
                <a><button type="button" @click="deleteData=false"> Close</button></a>
            </div>
        </div>
        <div class="overlay"></div>
        </div>
    </div>
</div>
</template>
<script>
import CommissionLinks from '../CommissionLinks.vue'
export default ({
    components:{
        CommissionLinks
    },
    data () {
        return {
            text          : "",  
            error         : "", 
            deleteerror   : "", 
            total_page    : 0,
            current_page  : 1,
            perpage       : 0,
            totalentries  : 0,
            entries       : "",
            noData        : "",
            navValues     : null,
            active        : false,
            sHead         : false,
            selectedItem  : false,
            deleteData    : false,
            yesbtn        : false,
            disppage      : false,
            selectAll     : false,
            StateList     : [],
            selItem       : [],
            selected      : [],
            columns: [   
                { text: "Product",    value: "product_name" },
                { text: "Group Name", value: "group_name"   },      
                { text: "State",      value: "state_type"  },
            ], 
        };
    },
    mounted(){
        this.getStateList();
    },
    methods: 
    {
        //Check Box functionality
        selectItem (item) {
        this.selectedItem = item
        },
        unSelectItem () {
        this.selectedItem = false
        },

        selectHead(val)
        {
            this.active =val;
        },

        checkSelected(event)
        {
        if(event.target.checked == true)
            this.selItem[event.target.value]=true;
        else
            this.selItem[event.target.value]=false;
        },
        
        //Commission List
        getStateList() 
        {  
            this.$store.dispatch("getajax", {
                method : "get",
                url    : `api/commissionstategroup${this.$route.fullPath.replace('/commission/stategroup','')}`,
                loader : "section",
            })
            .then((response) => {        
                if(response.data.data)
                {
                this.StateList    = response.data.data.data;  
                this.entries      = response.data.data.per_page;
                this.totalentries = response.data.data.total;           
                this.total_page   = response.data.data.last_page; 
                this.perpage      = response.data.data.per_page;            
                this.noData       = '';
                this.disppage     = true;
                }
                else
                {
                this.StateList  = [];
                this.total_page  = 0;
                this.disppage    = false;
                this.noData      = response.data.message;
                }
            })
            .catch((error) => {
            if (error.response&&error.response.data.status == 0) {          
                this.error = error.response.data.data;   
                this.errormessage=error.response.data.message;                   
            }
            });      
        },
        //pagination
        pagination(next_page) 
        { 
        if (this.$route.query.sort_type) 
            {
                var sort_type = this.$route.query.sort_type;
                var sort_by = this.$route.query.sort_by;
                this.$router.push({
                    query: { ...this.$route.query, sort_type: "", sort_by: "" },
                });
                this.$router.push({
                    query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
                });
            }     
            this.current_page = next_page;
            this.$router.push({ query: { ...this.$route.query, page: next_page } });      
        },

        //sorting
        sortType_change(sort_type,sort_by) 
        { 
        if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
                this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },}); 
        }  
        else{
                this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },});
        }
        },

        //show entries data
        showEntries(){
        if(this.entries<=100){
                this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } }); 
            }
        },

        // select table row
        select() {
        this.selected = [];
        if (!this.selectAll) {
            for (let i in this.StateList) {          
                    this.selected.push(this.StateList[i].id);
                    this.selItem[this.StateList[i].id]=true;
                    this.sHead=true;
                }
        }
        else
        {
                this.selItem = [];
                this.sHead   = false;
        }
        }, 
        
        //delete API popup
        deleteRow()
        {
            if(this.selected == '')
            {
                this.deleteData= true;
                this.text="Select State";
                this.yesbtn= false;
            }
            else{
                this.deleteData=true;
                this.text="Are you sure you want to delete?"
                this.yesbtn= true;
            }
        },
        deleteRecord() 
        {    
            var ApiCheck = this.selected.join(",");
            this.$store.dispatch("ajax", {
                method: "delete",
                url: `api/commissionstategroup/${ApiCheck}`,
            })
            .then((response) => {
                if (response.data.status === 1) {
                    this.deleteData= false;
                    this.$store.state.success_message = response.data.message;  
                    this.getStateList();        
                    this.selected=[];
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.yesbtn = false;
                    for(var item in error.response.data.data){
                        this.text = error.response.data.data[item][0];
                        break;
                    }
                    this.selected=[];
                }
            });
        },

        /* Edit State Group */
        editStateGroup(id)
        {
            this.$router.push('/commission/addstategroup?group_id='+id);
        }
    },
})
</script>
