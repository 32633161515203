<!--
 * Title      : Dashboard - Left Side
 * Developer  : Ankit Javiya
 * Description: This page is designed for Dashboard Page
 *
 -->
<template>
    <div class="col-md-5">
        <div class="dashboard emptypage">

                <div class="col-md-4" v-for="(navigation,i) in menuData" :key="i" v-show="navigation.module_type=='dashboardleft'">
                        <router-link :to="navigation.page_url" v-if="navigation.page_url!=null">
                        <div class="dashboard-box">
                            <div class="dashboard-image">
                                <div :class="navigation.icon_class"></div>
                            </div>
                            <div class="dashboard-name"><span> {{navigation.module_name}} </span></div>
                        </div>
                    </router-link>
            </div>

        </div>
        </div>
</template>
<script>
export default {
    name:"leftSide",
    data(){
        return{
            menuData: [
        {
          id: 1,
          module_type: "dashboardleft",
          module_name: "Commission",
          page_url: "commission/distribution",
          icon_class:"iinnoicon-commission_distribution"
        },
        {
          id: 2,
          module_type: "dashboardleft",
          module_name: "Carrier",
          page_url: "carrier",
          icon_class:"iinnoicon-report"
        },
        {
          id: 3,
          module_type: "dashboardleft",
          module_name: "Vendor",
           page_url: "vendor",
           icon_class:"iinnoicon-report"
        },
      ]
        }
    }

}

</script>


<style scoped>
.emptypage{
  height: 76vh;
}
</style>


