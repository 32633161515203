
<!--
 * Title      : Forgot Password
 * Developer  : Boddu Gopi
 * Description: This page is used to reset your password.
 * 
 -->

<template>
 <div class="loginpage">
    <div class="loginsection">
        <div class="row">
            <div class="col-md-6">
                <div class="loginimg">
                    <p></p>
                </div>
            </div>
            <div class="col-md-6">
                <form @submit="forgot_password">
                    <div class="loginboxpart">
                     <div class="loginform">
                        <div class="loginlog">
                            <img src="images/icons/gigly_logo.svg" alt="gigly">
                            <div class="cl"></div>
                            <span>{{$store.state.Messages.heading}}</span>
                        </div>
                        <div class="cl"></div>
                        <div class="login_formbox forgot" v-if="this.success==false">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="forgottitle">Forgot Your Password?</label>
                                    </div>
                                </div>
                                <div class="cl"></div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Enter your username to receive password reset link</label>
                                        <input type="text" placeholder="Enter Your Username" class="form-control"
                                          v-model="forgot_email" autocomplete="off" @input="errors.user_name ? (errors.user_name = null) : ''"
                                          :class="{'is-invalid':(submitted && $v.forgot_email.$error) || errors.user_name}"/>
                                        <div v-if="submitted && $v.forgot_email.$error && !errors.user_name" class="invalid-feedback"> The email field is required</div>
                                        <div v-if="errors.user_name " class="invalid-feedback">{{ errors.user_name[0] }}</div>
                                    </div>
                                <div class="col-md-12">
                                    <div class="loginbtnpart">
                                        <button type="submit" class="">Submit</button>
                                        <button type="button" class="" @click="Login">Cancel</button>
                                    </div>
                                </div>
                                <div class="cl"></div>                            
                        </div>
                      </div>
                        </div>
                        <div class="login_formbox forgot" v-if="this.success==true">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                  <label class="text-center">Reset Password Link has been sent to your email address. Please check your email.</label>
                                 <button type="submit" class=""  @click.prevent="Login">Login</button>
                              </div>
                            </div>                            
                            <div class="cl"></div>
                          </div>
                        </div>
                     </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      forgot_email: "",
      errors      : "",
      success     : false,
      submitted   : false,
    };
  },
  validations: {
    forgot_email: { required },
  },
  methods: {
    forgot_password(e) {
      e.preventDefault();
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      var usernameApi='';
      var apidata = {};
      if (this.$route.name == 'userlogin') {
          usernameApi = "api/userpwlink";
          apidata.user_name=this.forgot_email;
      }
      if (this.$route.name == 'associatelogin') {
          usernameApi = "api/userpwlink";
          apidata.type="Agent";
          apidata.user_name=this.forgot_email;
      }
      this.$store
        .dispatch("ajax", {
          method: "post",
          url:usernameApi,
          params: apidata,
        })
        .then((response) => {
          this.token = response.data.token;
          if (response.data.status == 1) {
            this.success=true;
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },
    Login(){
      if (this.$route.name == 'userlogin') {
        if(this.$route.path!='/user'){
          this.$router.push("/user"); 
        }
      }
      if (this.$route.name == 'associatelogin') {
        if(this.$route.path!='/associate'){
         this.$router.push("/associate"); 
        }
      }
    }
  },
};
</script>
<style scoped>
.invalid-feedback {
    float: left;
    font-size: 13px;
    color: red;
    position: absolute;
    bottom: -17px;
    display: inherit;
}
</style>