<!--
 * Title      : Release commission
 * Developer  : Ankit Javiya
 * Description: This page is designed for Release commission
 *
 -->
<template>
<div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle"><div class="iinnoicon-appointment"></div>Release Commission</div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <CommissionLinks />
        </div>
        <div class="cl"></div>

         <div class="maincontentarea">
            <div class="contentarea">
               <div class="member-quickprofilebox memberquickbox profileLinkCard">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="formpart">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="tab-formtitle iinnoicon-down_arrow activemode">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Release Commission</a>
                                            </div>
                                            <div class="cl"></div>
                                        </div>
                                    </div>
                                    <div class="row maxwidth1400 collapse in" id="collapse1" aria-expanded="false">


                                    <div class="col-md-3 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Transaction Type:<span class="required">*</span></label>
                                            <div class="selectbox iinnoicon-down_arrow">
                                                <select class="form-control" v-model="transType" tabindex="2"
                                                @change="errors.transType?errors.transType='':'',getduration($event)"
                                                :class="{ 'is-invalid': submitted && $v.transType.$error || errors.transType}">
                                                <option value="">Select Transaction Type</option>
                                                <option v-for="(value, name) in transTypeList" :key="name" :value="name">{{value}}</option>
                                            </select></div>
                                            <div v-if="submitted && $v.transType.$error && !errors.transType" class="invalid-feedback">
                                                <span v-if="!$v.transType.required"> {{$store.state.Messages.require}}</span>
                                            </div>
                                            <div v-if="errors.transType" class="invalid-feedback">{{errors.transType[0]}}</div>
                                        </div>
                                    </div>


                                        <div class="col-md-3 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Month-Year:<span class="required">*</span></label>
                                                <div class="cl"></div>
                                                <Datepicker :minimumView="'month'" :maximumView="'month'" placeholder="MM/YYYY" v-model="monthyear" tabindex="2"
                                :format="dispString" :highlighted="state.highlighted" :disabled-dates="state.disabledDates"  @input="dispDate"/>
                                 <div v-if="submitted && $v.monthyear.$error && !errors.monthyear" class="invalid-feedback">
                                    <span v-if="!$v.monthyear.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.month_year" class="invalid-feedback positionstatic">{{ errors.month_year[0] }}</div>


                                            </div>
                                        </div>

                                        <div class="col-md-3 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Duration:<span class="required">*</span></label>
                                                <div class="selectbox iinnoicon-down_arrow">
                                                <select class="form-control" v-model="duration" tabindex="2" @change="errors.duration?errors.duration='':''"
                                                    :class="{ 'is-invalid': submitted && $v.duration.$error || errors.duration}">
                                                    <option value="">Select Duration</option>
                                                    <option v-for="(value, name) in durationList" :key="name" :value="name">{{value}}</option>

                                                </select></div>
                                                <div v-if="submitted && $v.duration.$error && !errors.duration" class="invalid-feedback">
                                                    <span v-if="!$v.duration.required"> {{$store.state.Messages.require}}</span>
                                                </div>
                                                <div v-if="errors.duration" class="invalid-feedback">{{errors.duration[0]}}</div>
                                            </div>
                                        </div>

                                         <div class="col-md-3 col-sm-6">
                                            <div class="form-group">
                                                 <div>
                                                <button class="mt20" type="button" tabindex="5"
                                                @click="clearAll()">Clear</button>
                                                <button class="fl mt20 mr10" type="button" tabindex="4" @click="getcommission()">Submit</button>
                                            </div>
                                            </div>
                                        </div>



                                        <div class="cl"></div>

                                    </div>
                                </div>
                            </div>
                            <div class="cl"></div>
                        </div>
                    </form>
                </div>
            <div class="cl"></div>

             <div class="memberquickbox mt20" v-if="isDisplay == true">
                    <div class="tablesection">
                        <div class="tablebox">
                            <table>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Description</th>
                                        <th>Total Amount/Count</th>
                                    </tr>
                                </thead>
                                <tbody>
									<tr>
                                        <td>1</td>
                                        <td>Total company available for release commission:</td>
                                        <td>{{releaseData['totalAgencyAcailable']}}</td>
									</tr>

                                    <tr>
                                        <td>2</td>
                                        <td>Released commission in companies:</td>
                                        <td>{{releaseData['totalAgency']}}</td>
									</tr>

                                    <tr>
                                        <td>3</td>
                                        <td>Released commission amount of companies:</td>
                                        <td>{{releaseData['totalAgencyAmount']}}</td>
									</tr>

                                    <tr>
                                        <td>4</td>
                                        <td>Total Associates available for release commission:</td>
                                        <td>{{releaseData['totalAgentAvailable']}}</td>
									</tr>

                                    <tr>
                                        <td>5</td>
                                        <td>Released commission in Associates:</td>
                                        <td>{{releaseData['totalAgent']}}</td>
									</tr>

                                    <tr>
                                        <td>6</td>
                                        <td>Released commission amount of Associates:</td>
                                        <td>{{releaseData['totalAgentAmount']}}</td>
									</tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            <div class="cl"></div>

        </div>

    </div>

</div>
</template>
<script>
import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import CommissionLinks from '../CommissionLinks.vue'
import moment  from 'moment';
//import functionmixins from "../../../mixins/functionmixins.js";
import { required } from "vuelidate/lib/validators";
import vClickOutside from 'v-click-outside';


export default ({
    components:{
        CommissionLinks,
        Datepicker
    },
    data() {
        return {
            errors          : "",
            transType       : "",
            dispString      : "",
            duration        : "",
            commFromDate    : "",
            monthyear       : "",
            submitted       : "",
            search          : false,
            errorModal      : false,
            errorMsg    : "",
            transTypeList   : [],
            durationList    : [],
            loginToken      : "",
            releaseData     : [],
            isDisplay       : false,
            state         : {
                disabledDates: {
                    to: new Date(2021, 4, 1),
                    from: new Date()
                }
            },
        }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    validations: {
        transType       : { required },
       // commFromDate    : { required },
       monthyear:       {required},
        duration        : { required },
    },
      mounted()
    {
        this.getTransactionTypeList();

    },
    methods: {

        dispDate()
        {
            if(this.errors.month_year)
                this.errors.month_year='';
            var year  = moment(this.monthyear).format("YYYY");
            var month = moment(this.monthyear).format("MM");
            this.dispString = month+"/"+year;
        },


        /* get duration base on transaction typer */
        getduration($event){
            var val = $event.target.value;
            this.getDurationList(val);

        },

        /* Get Transaction type List */
        getTransactionTypeList()
        {
            this.transTypeList    = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "section",
                url     : "api/gettranstypelist"
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.transTypeList = response.data.data;
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },



        /* Get Duration List */
        getDurationList(transType)
        {
            this.durationList    = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "section",
                url     : "api/getalldurationlist"
            })
            .then((response) => {
                if(response.data.data)
                {
                    if(transType =='NewBusiness'){
                            this.durationList = response.data.data.new;
                    }

                    else if(transType =='Renewal'){
                            this.durationList = response.data.data.renewal;
                    }
                    else{
                             this.durationList    = [];
                    }

                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },

        checkIsValid () {
            this.submitted = true;
        },

        getcommission(){
              this.submitted = true;

              this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

        if(this.transType!="" && this.monthyear!="" && this.duration!="")
        {
            this.getReleaseCommission();
        }

        },

        clearAll()
        {
            this.transType   = "";
            this.monthyear ="";
            this.duration = "";
            this.releaseData = [];
            this.errors = [];
            this.submitted = false;
            this.isDisplay = false;

        },
        /*  Date convertion from backend to frontend  */
        dateFrontend(dt) {
             return moment(dt, "MM-DD-YYYY").format("YYYY-MM-DD");
        },

        /* Date conversion from frontend to backend */
        dateBackend(dt) {
             return moment(dt, "YYYY-MM-DD").format("MM-DD-YYYY");
        },

        // call api and get login token
        getReleaseCommission(){

        this.$store.state.ajax.pageloader=true;
        let hostURL = location.host;
        let baseURL  = '';

          let userName = '';
          let Pwd  = '';

        if(hostURL.includes('localhost'))
        {
            baseURL = 'https://api-precisetech.test/api/';
            userName = 'walker@codestudio.io';
            Pwd  = 'Dark0425!!';

        }else{
            baseURL = 'https://admin-api.precisetech.ai/api/';
            userName = 'walker@codestudio.io';
            Pwd  = 'Dark0425!!';
        }


            let checkUserLink = baseURL+ 'checkuser';
            let checkLoginLink = baseURL+ 'checklogin';
            let commLink = baseURL+ 'reportpaymentdistr';



          // CALL CHECKUSER API
           axios.post(checkUserLink, {
               username: userName,
                })
                .then(response => {
                     if (response.data.status == 1){

                          if(response.data.data?.token && response.data.data.token!="")
                          {
                              let tempToken = response.data.data?.token;

                              // CALL CHECKLOGIN API
                                axios.post(checkLoginLink,
                                 {
                                    username: userName,
                                    password:Pwd,
                                    token:tempToken,
                                    }).then(loginResp => {

                                         let accessToken = loginResp.data.data.access_token;

                                        if(loginResp.data.status==1){

                                            var monyear     = "";
                                            if(this.monthyear)
                                            {
                                                var year    = moment(this.monthyear).format("YYYY");
                                                var month   = moment(this.monthyear).format("MM");
                                                    monyear = month+"-"+year;
                                            }
                                            else
                                                monyear     = "";

                                         // CALL RELEASE COMM. API
                                         axios.get(commLink ,
                                         { headers: {"Authorization" : `Bearer ${accessToken}`},
                                          params  :  {
                                                transaction_type       : this.transType,
                                                duration               : this.duration,
                                                month_year             : monyear,
                                            },
                                             loader  : "section" })
                                             .then(res => {

                                               if(res.data.status==1)
                                               {
                                                    this.releaseData = res.data.data;
                                                    this.isDisplay = true;

                                                    this.$store.state.ajax.pageloader=false;
                                               }
                                           }).catch((loginErr) => {
                                               this.$store.state.ajax.pageloader=false;
                                                this.$store.state.error_message= loginErr.data.message;
                                            });

                                        }


                                    }).catch((err) => {
                                            this.$store.state.ajax.pageloader=false;
                                               this.$store.state.error_message= err.data.message;
                                    });
                          }
                     }
                     else{
                              this.$store.state.ajax.pageloader=false;
                              this.$store.state.error_message= 'Token not found';
                          }

                    })
                .catch((error) => {
                  //  console.log(error.response.data);
                     if (error.response && error.response.data.status==0)
                     {
                         this.$store.state.ajax.pageloader=false;
                        this.$store.state.error_message= error.response.data.message;
                     }
                });
        },

    }

})
</script>
