<template>
    <div class="pagesection">
     <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle"><div class="iinnoicon-commission"></div>Setup</div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <li class="tooltipbox">
                        <router-link to="/commission/addsetupcommission">
                        <div class="iinnoicon-add_commission_setup"></div>
                    </router-link><span class="tooltips160">Add Setup</span></li>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
           <CommissionLinks />
        </div>

        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="tablesection">
                    <div class="tablebox">
                    <table>
                        <thead>
                            <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                                <th class="width30 textcenter" >
                                    <div class="table-checkbox">
                                    <input type="checkbox"  v-model="selectAll" @click="select" id="selectAll" v-if="active == true || sHead == true" />
                                    </div>
                                </th>
                                <th class="width40 textcenter">No.</th>
                                <th class="handpointer"
                                v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)"
                                :class="column.class">
                                <span >{{column.text}}</span>
                                 <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                 <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                               </th>
                                <th class="width50 textcenter"> </th>
                                <!-- <th class="width80 textcenter">Action</th> -->
                            </tr>
                        </thead>
                        <tbody v-for="(list,i) in StateList" :key="i" class="tbodysection">
                            <tr @mouseover="selectItem(list)" @mouseleave="unSelectItem(list)" v-bind:class="{ 'trselected': selItem[list.id] === true || list === selectedItem}">
                                <td class="width30 textcenter">
                                    <div class="table-checkbox">
                                        <input type="checkbox" :value="list.product_id" :name="list.product_id" v-model="selected" v-if="list === selectedItem || selItem[list.id] == true" @change="checkSelected($event)"/>
                                    </div>
                                </td>
                                <td class="width40 textcenter">{{perpage * (current_page - 1) + i + 1}}</td>
                                <td class="width700 textleft handpointer" @click="getFeesData(list.product_id,i)">{{list.product_name}}</td>
                                <!-- <td class="width150 textcenter">{{list.state_group_name}}</td> -->
                                <td class="width50 textcenter">
                                    <div
                                     v-bind:class="{ 'rotate180': opened.includes(i), 'rotate90': !opened.includes(i) }"
                                    @click="getFeesData(list.product_id,i), toggleRow(i)" class="aghistory-tableicon"></div>
                                    </td>
                            </tr>
                            <tr class="noteviewbox" v-if="shown==i" style="display: table-row;">
                                <td></td><td></td>
                                <td colspan="2">
                                    <div class="tablesection">
                                    <div class="tablebox">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th class="width200">Fee</th>
                                                <th class="width200">State Group</th>
                                                <th class="width100 textcenter">DISTRIBUTION BASED</th>
                                                <th class="width50 textcenter">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tbodysection">
                                            <tr v-for="(flist,i) in feeList" :key="i">
                                                <td class="width200">{{flist.fee_name}}</td>
                                                <td class="width200">{{flist.state_group_name}}</td>
                                                <td class="width150 textcenter">{{flist.distr_base}}</td>
                                                <td class="width80 textcenter">
                                                    <div class="actionbox" @click="editsetup(flist.id)">
                                                        <div class="iinnoicon-edit"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody  v-if="this.noData != ''">
                            <tr>
                                <td colspan="15">
                                    <div class="warning">
                                    <div class="positive-error" style="border:none">
                                        <p>{{noData}}</p>
                                    </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
                <div class="paginationsecion" v-if="disppage">
                <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div>
                <div class="cl"></div>
                <div class="shorting">
                <label>Show</label>
                <div class="shorbox">
                <div class="selectbox iinnoicon-down_arrow">
                <select v-model="entries" @change="showEntries()" class="form-control">
                <!-- <option value="">select</option>  -->
                <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                </select></div>
                </div>
                <span>entries</span>
                </div>
                <div class="paginationbox" v-if="this.total_page > 1">
                    <ul class="pagination">
                        <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                        <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"><a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a><a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                        <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                    </ul>
                </div>
            </div>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
     <!-- //delete Popup -->
        <div v-if="deleteData">
        <div class="deletebox">
            <div class="deleteimg"><span>!</span></div>
                <div class="cl"></div>
                <div class="textboxsection">
                <p>{{text}}</p>
                <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="yesbtn">Delete</button></a>
                <a><button type="button" @click="deleteData=false"> Close</button></a>
            </div>
        </div>
        <div class="overlay"></div>
        </div>
</div>
</template>
<script>
import axios from 'axios';
import CommissionLinks from "../CommissionLinks.vue";
export default {
    components:{
       CommissionLinks
    },
    data(){
  return{
            text          : "",
            error         : "",
            deleteerror   : "",
            total_page    : 0,
            current_page  : 1,
            perpage       : 0,
            totalentries  : 0,
            entries       : "",
            noData        : "",
            navValues     : null,
            shown         : null,
            active        : false,
            sHead         : false,
            selectedItem  : false,
            deleteData    : false,
            yesbtn        : false,
            disppage      : false,
            selectAll     : false,
            opened          : [],
            StateList     : [],
            selItem       : [],
            selected      : [],
            feeList       : [],
            ipAddress     : "",
      columns: [
        { text: "PRODUCT Name", value: "product_name" ,class:'textleft mobwidth700'},
        // { text: "STATE GROUP", value: "state_group_name", class:'width150 textcenter' },
        ],

     }
   },
    mounted(){
        this.getStateList();
        this.getIP();
    },
    methods:
    {
         // table collpase
         toggleRow(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        },



        editsetup(id){
            this.$router.push('/commission/addsetupcommission?comm_id='+id);
        },
        //Check Box functionality
        selectItem (item) {
        this.selectedItem = item
        },
        unSelectItem () {
        this.selectedItem = false
        },

        selectHead(val)
        {
            this.active =val;
        },

        checkSelected(event)
        {
        if(event.target.checked == true)
            this.selItem[event.target.value]=true;
        else
            this.selItem[event.target.value]=false;
        },

        //Commission List
        getStateList()
        {
            this.$store.dispatch("getajax", {
                method : "get",
                url    : "api/accountcommission",
                loader : "section",
                params: {
                sort_type: this.$route.query.sort_type,
                sort_by: this.$route.query.sort_by,
                per_page: this.$route.query.per_page,
                page: this.$route.query.page,
           }
            })
            .then((response) => {
                if(response.data.data)
                {
                this.StateList    = response.data.data.data;
                this.entries      = response.data.data.per_page;
                this.totalentries = response.data.data.total;
                this.total_page   = response.data.data.last_page;
                this.perpage      = response.data.data.per_page;
                this.noData       = '';
                this.disppage     = true;
                }
                else
                {
                this.StateList  = [];
                this.total_page  = 0;
                this.disppage    = false;
                this.noData      = response.data.message;
                }
            })
            .catch((error) => {
            if (error.response&&error.response.data.status == 0) {
                this.error = error.response.data.data;
                this.errormessage=error.response.data.message;
            }
            });
        },
        /* Get Fee based on product  */
        getFeesData(id,i)
        {
            this.feeList    = [] ;
            if(this.shown == i)
                this.shown = null;
            else
            {
                this.$store.dispatch("getajax", {
                    method : "get",
                    url    : "api/getproductwisefeesetup/"+id,
                    loader : "section",
                })
                .then((response) => {
                    if(response.data.data)
                    {
                        this.feeList = response.data.data;
                        this.shown   = i;
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.data;
                    this.shown  = null;
                })
            }
        },
        //pagination
        pagination(next_page)
        {
        if (this.$route.query.sort_type)
            {
                var sort_type = this.$route.query.sort_type;
                var sort_by = this.$route.query.sort_by;
                this.$router.push({
                    query: { ...this.$route.query, sort_type: "", sort_by: "" },
                });
                this.$router.push({
                    query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
                });
            }
            this.current_page = next_page;
            this.$router.push({ query: { ...this.$route.query, page: next_page } });
        },

        //sorting
        sortType_change(sort_type,sort_by)
        {
        if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){
                this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },});
        }
        else{
                this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },});
        }
        },

        //show entries data
        showEntries(){
        if(this.entries<=100){
                this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } });
            }
        },

        // select table row
        select() {
        this.selected = [];
        if (!this.selectAll) {
            for (let i in this.StateList) {
                    this.selected.push(this.StateList[i].id);
                    this.selItem[this.StateList[i].id]=true;
                    this.sHead=true;
                }
        }
        else
        {
                this.selItem = [];
                this.sHead   = false;
        }
        },

        //delete API popup
        deleteRow()
        {
            if(this.selected == '')
            {
                this.deleteData= true;
                this.text="Select State";
                this.yesbtn= false;
            }
            else{
                this.deleteData=true;
                this.text="Are you sure you want to delete?"
                this.yesbtn= true;
            }
        },
        deleteRecord()
        {
            var ApiCheck = this.selected.join(",");
            this.$store.dispatch("getajax", {
                method: "delete",
               //url: "api/accountcommission/7/"+ this.ipAddress,
                url: `api/accountcommission/${ApiCheck}`,
                params:{
                    ip_address :this.ipAddress
                }
            })
            .then((response) => {
                if (response.data.status === 1) {
                    this.deleteData= false;
                    this.$store.state.success_message = response.data.message;
                    this.getStateList();
                    this.selected=[];
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.yesbtn = false;
                    for(var item in error.response.data.data){
                        this.text = error.response.data.data[item][0];
                        break;
                    }
                    this.selected=[];
                }
            });
        },
        getIP(){
            axios
            .get('https://api.ipify.org?format=json&callback=getIP')
            .then((response) => {
                this.ipAddress=response.data.ip;
             });

        }

    },

}
</script>