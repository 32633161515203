<template>
<div class="pagesection" >
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle"><div class="iinnoicon-commission"></div>{{$route.query.comm_id?'Edit Setup':'Add Setup'}}</div>
            <div class="quickmenubox toprighticon">

            </div>
        </div>
        <div class="cl"></div>
        <!-- <div class="pageheadingbox mobquickbox">
             <CommissionLinks />
        </div> -->
      <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
               <div class="member-quickprofilebox memberquickbox profileLinkCard">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="formpart">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="tab-formtitle iinnoicon-down_arrow activemode">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Setup Commission</a>
                                            </div>
                                            <div class="cl"></div>
                                        </div>
                                    </div>
                                    <div class="row maxwidth1400 collapse in" >
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Product:<span class="required">*</span></label>
                                                <div class="selectbox iinnoicon-down_arrow">
                                                    <select class="form-control" v-model="productID" @change="getProductId($event)" tabindex="1"
                                                    :class="{ 'is-invalid': submitted && $v.productID.$error || errors.product_id}">
                                                        <option value="null">Select Product</option>
                                                        <option  v-for="(list ,i) in productList" :value="list.id" :key="i">{{list.product_name}}</option>
                                                     </select>
                                                </div>
                                                 <div v-if="submitted && $v.productID.$error && !errors.product_id" class="invalid-feedback">
                                                   <span v-if="!$v.productID.required"> {{$store.state.Messages.require}}</span>
                                                  </div>
                                                <div v-if="errors.product_id" class="invalid-feedback">{{errors.product_id}}</div>
                                                 <div v-if="$store.state.Messages.Perrors.product_id" class="invalid-feedback">{{$store.state.Messages.Perrors.product_id}}</div>

                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Fee:<span class="required">*</span></label>
                                                <div class="selectbox iinnoicon-down_arrow">
                                                    <select class="form-control" v-model="feeID" @change="getFeeID" tabindex="2"
                                                    :class="{ 'is-invalid': submitted && $v.feeID.$error || errors.fee_id}"
                                                    @input="errors.fee_id?errors.fee_id='':''">
                                                        <option value="null">Select Fee</option>
                                                        <option v-for="(list, i) in feeList" :key="i" :value="list.fee_id">{{list.fee_name}}</option>
                                                    </select>
                                                 </div>
                                                <div v-if="submitted && $v.feeID.$error && !errors.fee_id" class="invalid-feedback">
                                                   <span v-if="!$v.feeID.required"> {{$store.state.Messages.require}}</span>
                                                   </div>
                                                <div v-if="errors.fee_id" class="invalid-feedback">{{errors.fee_id[0]}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Distribution based:<span class="required">*</span></label>
                                                <div class="selectbox iinnoicon-down_arrow">
                                                    <select class="form-control" @change="selectDistribution($event)" v-model="Dsection" tabindex="3"
                                                    :class="{ 'is-invalid': submitted && $v.Dsection.$error || errors.distribution_based}">
                                                     <option value="">Select Distribution</option>
                                                      <option v-for="(list, i) in DistributionList" :value="i" :key="i">{{list}}</option>
                                                    </select>
                                                </div>
                                                <div v-if="submitted && $v.Dsection.$error && !errors.distribution_based" class="invalid-feedback">
                                                   <span v-if="!$v.Dsection.required"> {{$store.state.Messages.require}}</span>
                                                   </div>
                                                <div v-if="errors.distribution_based" class="invalid-feedback">{{errors.distribution_based[0]}}</div>
                                            </div>
                                        </div>
                                        <div class="percentage distribution_options" v-if="Percentage">
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">State Group :</label>
                                                    <div class="selectbox iinnoicon-down_arrow">
                                                        <select class="form-control" v-model="stateID" tabindex="4"
                                                        :class="{ 'is-invalid': submitted && $v.stateID.$error || errors.state_group_id}"
                                                        @change="errors.state_group_id?errors.state_group_id='':''"
                                                        >
                                                            <option  value="">Select Group</option>
                                                            <option v-for="(list ,i ) in StageGroupList" :key="i" :value="list.state_group_id">{{list.group_name}}</option>
                                                        </select>
                                                    </div>
                                                 <div v-if="submitted && $v.stateID.$error && !errors.state_group_id" class="invalid-feedback">
                                                   <span v-if="!$v.stateID.required"> {{$store.state.Messages.require}}</span>
                                                   </div>
                                                <div v-if="errors.state_group_id" class="invalid-feedback">{{errors.state_group_id[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Reserve Percentage: </label>
                                                    <input type="number" tabindex="5" v-model="firstReserve" placeholder="Enter Reserve Percentage"
                                                    :class="{ 'is-invalid': submitted && $v.firstReserve.$error || errors.first_year_reserve}"
                                                     @change="errors.first_year_reserve?errors.first_year_reserve='':''"
                                                     maxlength="50" class="form-control">
                                                 <div v-if="submitted && $v.firstReserve.$error && !errors.first_year_reserve" class="invalid-feedback">
                                                   <span v-if="!$v.firstReserve.required"> {{$store.state.Messages.require}}</span>
                                                   </div>
                                                 <div v-if="errors.first_year_reserve" class="invalid-feedback">{{errors.first_year_reserve[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Renewal Reserve Percentage: </label>
                                                    <input type="number" tabindex="6" v-model="renewalReserve" placeholder="Enter Reserve Percentage"
                                                    :class="{ 'is-invalid': submitted && $v.renewalReserve.$error || errors.renewal_year_reserve}"
                                                     @change="errors.renewal_year_reserve?errors.renewal_year_reserve='':''" maxlength="50" class="form-control">
                                                 <div v-if="submitted && $v.renewalReserve.$error && !errors.renewal_year_reserve" class="invalid-feedback">
                                                   <span v-if="!$v.renewalReserve.required"> {{$store.state.Messages.require}}</span>
                                                   </div>
                                                  <div v-if="errors.renewal_year_reserve" class="invalid-feedback">{{errors.renewal_year_reserve[0]}}</div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="cl"></div>
                                        <div class="percentage-flat distribution_options" v-if="PercentageFlat == true">
                                           <PercentageFlat :productID="productID" :feeID="feeID" :Dsection="Dsection" @clicked="getPercentageInfo" :submitted="submitted" :errors="errors" :updateCommissionData="updateCommissionData"/>
                                        </div>
                                        <div class="col-md-12" v-if="PercentageFlat == false">
                                            <div class="fr">
                                                <button class="fl mt20 mr10" type="button" tabindex="7" @click="AddCommission">Save</button>
                                                <button class="fr mt20" type="button" tabindex="8" @click="$router.push('/commission/setupcommissionlist')">Cancel</button>
                                            </div>
                                            <!-- <div class="fr" v-if="!this.Dsection">
                                                <a > <button class="fl mt20 mr10" type="button" tabindex="18">Cancel</button></a>
                                                <button class="fr mt20" type="button" tabindex="18" @click="AddCommission">Save</button>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cl"></div>
                        </div>
                    </form>
                </div>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
</div>
</template>
<script>
import PercentageFlat from './PercentageFlat.vue'
import { required } from "vuelidate/lib/validators";
// import CommissionLinks from "../CommissionLinks.vue";
export default {
    components:{
    //    CommissionLinks,
       PercentageFlat,
    },
 data(){
  return{
     DistributionList:[],
     StageGroupList:[],
     BaserateList:[],
     productList:[],
     feeList:[],
     selectPlan:"",
     PlanList:"",
     productID:null,
     pID:"",
     errors:{},
     Percentage:false,
     PercentageFlat:false,
     feeID:null,
     stateID:"",
     distributionBased:"",
     rateBase:"",
     rate:"",
     rateType:"",
     firstReserve:[],
     renewalReserve:[],
     PlanLevelList:[],
     selectedID:[],
     arrayrates:[],
     arrayrenewal:[],
     Dsection:"",
     errorsMessage:[],
     form: {
          display_id: []
        },
    rates:[],
    submitted:false,
    updateCommissionData:{}
    }
   },
    validations: {
        productID    : { required },
        feeID      : { required },
        stateID      : { required },
        Dsection      : { required },

        firstReserve:{ required },
        renewalReserve:{ required },
    },
   methods:{
    selectDistribution(event){
    if(this.errors.distribution_based)
     this.errors.distribution_based='';
     this.Dsection=event.target.value
     if(this.Dsection=='%'){
         this.Percentage=true
         this.PercentageFlat=false

     }
     if(this.Dsection=='%flat' || this.Dsection=='flat'){
         this.PercentageFlat=true
         this.Percentage=false
        }
     },
    selectPlanType(event){
     this.selectPlan=event.target.value;
     this.getPlanlevel();
    },
    getDistributionList() {
       this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/getdistributionlist",
        })
        .then((response) => {
          if (response.data.status === 1) {
             this.DistributionList=response.data.data;
            }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.errorsMessage=error.response.data.data
           }
        });
    },
    getStageGroupList() {
     if(this.$route.query.comm_id){
         this.pID=this.productID
      }
      else{
          this.pID=this.$route.query.product_id
      }
       this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/getstategroup/" + this.pID,
        })
        .then((response) => {

          if (response.data.status === 1) {
             this.StageGroupList=response.data.data;

           if(this.$route.query.comm_id)
            this.stateID=this.updateCommissionData.state_group_id;
            }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.errorsMessage=error.response.data.data;
           }
        });
    },
    // getPlanlevel() {
    //    this.$store
    //     .dispatch("getajax", {
    //       method: "get",
    //       url: `api/getplan?type_name=${this.selectPlan}&product_id=${this.productID}`,
    //     })
    //     .then((response) => {
    //       if (response.data.status === 1) {
    //           var i;
    //           var o;
    //          this.PlanLevelList=response.data.data;
    //          for(i in this.PlanLevelList){
    //             this.rates[i]=this.PlanLevelList[i].rate;
    //          }
    //         for (o = 0; o < this.PlanLevelList.length; o++) {
    //         this.selectedID.push(this.PlanLevelList[o].display_id);

    //         }

    //         }
    //     })
    //     .catch((error) => {
    //       if (error.response.data.status == 0) {
    //        this.errors=error.response.data.data
    //        }
    //     });
    // },

    // getPlanList() {
    //    this.$store
    //     .dispatch("getajax", {
    //       method: "get",
    //       url: "api/getratebaselist ",
    //     })
    //     .then((response) => {
    //       if (response.data.status === 1) {
    //          this.PlanList=response.data.data;
    //         }
    //     })
    //     .catch((error) => {
    //       if (error.response.data.status == 0) {
    //        this.errors=error.response.data.data
    //        }
    //     });
    // },
    getFeeList() {
      if(this.$route.query.comm_id){
         this.pID=this.productID
      }
      else{
          this.pID=this.$route.query.product_id
      }
       this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/getfee/" + this.pID,
        })
        .then((response) => {
          if (response.data.status === 1) {
             this.feeList=response.data.data;
              if(this.$route.query.fee_id!=null){
              this.feeID=this.$route.query.fee_id;
              }
            }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.errorsMessage=error.response.data.data
           }
        });
    },
      getProductId(event){
        if(this.errors.product_id||this.$store.state.Messages.Perrors.product_id) {
        this.errors.product_id='';
        this.$store.state.Messages.Perrors.product_id='';
        }
        this.productID=parseInt(event.target.value)
        if(this.$route.query.product_id!=''){
        this.$router.push({ query: { ...this.$route.query,  product_id:this.productID } });

        }

      },
      getFeeID(){
       if(this.$route.query.fee_id!=''){
        this.$router.push({ query: { ...this.$route.query,  fee_id:this.feeID } });
        }
      },

    getProductLIst() {
       this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/product",
          loader:'section'
        })
        .then((response) => {
          if (response.data.status === 1) {
             this.productList=response.data.data;
              if(this.$route.query.product_id!=null){
              this.productID=this.$route.query.product_id;
               this.getFeeList();
               this.getStageGroupList();
              }
            }

        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.errorsMessage=error.response.data.data
           }
        });
    },

    getPercentageInfo(stateID,selectPlan,rates,form,selectedID)
        {
            this.stateID=stateID;
            this.form=form;
            this.selectPlan=selectPlan;
            this.rates=rates;
            this.selectedID=selectedID;
           // this.submitted=submitted;
        //console.log(this.form)
     },
    AddCommission(){
        if(this.Dsection=='%'){
            this.arrayrenewal='';
            this.arrayrates='';
            this.arrayrates=this.firstReserve;
            this.arrayrenewal=this.renewalReserve;
        }
        if(this.Dsection=='%flat' || this.Dsection=='flat' ){
           this.arrayrenewal=[];
           this.arrayrates=[];
        var i;
        for(i in this.form){
         this.arrayrenewal.push(this.form[i]);
        }
       var j=0;
        for(j=0;j<this.rates.length;j++){
         this.arrayrates.push(this.rates[j]);
        }
       }
       var url="";
       var method     = "";
       if(this.$route.query.comm_id)
        {
            method     = "put";
            url        = "api/accountcommission/"+this.$route.query.comm_id;
        }
        else
        {
            method     = "post";
            url        = "api/accountcommission"
            }
        this.submitted = true;
        this.$v.$touch();
            if (this.$v.$invalid) {
                return;
        }
       this.$store
        .dispatch("ajax", {
          method   :method,
          url      :url,
          params:{
              product_id:this.productID,
              fee_id:this.feeID,
              state_group_id :this.stateID,
              distribution_based:this.Dsection ,
              rate_base :this.selectedID.toString(),
              rate:this.arrayrates.toString(),
              rate_type:this.selectPlan,
              first_year_reserve :this.arrayrates.toString(),
              renewal_year_reserve:this.arrayrenewal.toString(),
             }
        })
        .then((response) => {
          if (response.data.status === 1) {
               this.$store.state.success_message=response.data.message;
                if(this.$route.query.comm_id){
                this.updateCommission();
                }
                this.errors={};
               this.submitted=false;
            }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.errors=error.response.data.data
           }
        });
    },
    cleasAll(){
      this.feeID='';
      this.Dsection ='';
    },
    updateCommission(){
        this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/accountcommission/" + this.$route.query.comm_id,

        })
        .then((response) => {
          if (response.data.status === 1) {
              this.updateCommissionData=response.data.data
              this.productID=response.data.data.product_id.toString();
              this.feeID=response.data.data.fees_id.toString();
              this.stateID=response.data.data.state_group_id;
              this.Dsection =response.data.data.distr_base;
              this.firstReserve=response.data.data.first_year_reserve_percentage;
              this.renewalReserve=response.data.data.renewal_reserve_percentage;
              this.getFeeList();
              this.getStageGroupList();
              this.getDistributionList();
              this.updateDistribution();
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.errorsMessage=error.response.data.data
           }
        });
    },
    //Distribution based
    updateDistribution(){
        if(this.Dsection=='%'){
         this.Percentage=true
         this.PercentageFlat=false
     }
     if(this.Dsection=='%flat' || this.Dsection=='flat'){
         this.PercentageFlat=true
          this.Percentage=false
     }
    }
   },

   mounted(){
       if(!this.$route.query.comm_id){
    //    this.getFeeList();
    //    this.getStageGroupList();
       }
       this.getDistributionList();
       this.getProductLIst();
        if(this.$route.query.comm_id)
         this.updateCommission();

      // this.getPlanList();
   }

}
</script>