<!--
 * Title      : Dashboard
 * Developer  : Gopi Boddu
 * Description: This page is designed for Dashboard Page
 *
 -->
<template>
<div class="pagesection">
    <div class="rightsection">
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <form>
                    <div class="row">
                         <LeftSection/>
                         <RightSection/>
                        <!-- <div class="col-md-12">
                            <div class="emptypage"><p>Design in Progress</p></div>
                        </div> -->
                    </div>
                </form>
                <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
</div>
</template>
<script>
import LeftSection from '@/components/Dashboard/LeftSection.vue'
import RightSection from '@/components/Dashboard/RightSection.vue'
export default {
    components: {
        RightSection,
        LeftSection,
    },
}
</script>