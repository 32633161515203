import Vue from 'vue'
import Vuex from 'vuex'
import ajax from './modules/Ajax'
import Login from './modules/Login'
import Messages from './modules/Messages'
import PerPage from './modules/PerPage'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ajax,
    Login,
    Messages,
    PerPage
  },
  state: {
    navigation:[],
    error_message:'',
    success_message:'',
    deletePopup:false,
    deleteconfirmation:false,
  },
})
