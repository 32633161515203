<template>
  <div class="loginpage">
    <div class="loginsection">
      <div class="row">
        <div class="col-md-6">
          <div class="loginimg">
            <p></p>
          </div>
        </div>
        <div class="col-md-6">
            <username v-if="this.$root.page_type =='username'"/>
            <userlogin v-if="this.$root.page_type =='userlogin'"/>
            <password v-if="this.$root.page_type =='password'"/>
            <forgotpassword v-if="this.$root.page_type =='forgotpassword'"/>
            <!-- <resetpassword v-if="this.$root.page_type =='resetpassword'"/> -->
            <whitelistip v-if="this.$root.page_type =='whitelistip'||this.$root.page_type =='whitelistipstep2'||this.$root.page_type =='whitelistipstep3'"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import username from '@/components/Login/username.vue'
import userlogin from '@/components/Login/userlogin.vue'
import password from '@/components/Login/Password.vue'
import forgotpassword from '@/components/Login/forgotpassword.vue'
//import resetpassword from '@/components/login/resetpassword.vue'
import whitelistip from '@/components/Login/whitelistip.vue'
export default {
  components: {
     username,
     userlogin,
     password,
     forgotpassword,
     //resetpassword,
     whitelistip
  },
  // mounted() {
  //   if (this.$route.name == 'login'&&this.$route.query.token) {
  //     this.$root.page_type = "resetpassword";
  //   }
  // }
}
</script>
