<!--
 * Title      : Dashboard
 * Developer  : Gopi Boddu
 * Description: This page is designed for Dashboard Page
 *
 -->
<template>
<div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle"><div class="iinnoicon-reports"></div> Payment Report</div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea memberquickbox">
                <div class="formpart">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='ReportName'}">
                                <a @click="toggleSection('ReportName')">Payment Report</a>
                            </div>
                            <div class="cl"></div>
                        </div>
                    </div>
                    <div class="row" v-if="opentab=='ReportName'">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Carriers:<span class="required">*</span></label>
                                <div class="selectbox iinnoicon-down_arrow">
                                    <select class="form-control" v-model="carrier_type" tabindex="1" @change="getPaymentData()"
                                    :class="{ 'is-invalid': submitted && errors.carrier_type}" @input="errors.report_id?errors.report_id='':''">
                                        <option value="">Select Carrier</option>
                                        <option v-for="(payment,i) in paymentList" :key="i" :value="payment.report_id">{{payment.report_name}}</option>
                                    </select>
                                </div>
                                <div v-if="submitted && $v.carrier_type.$error && !errors.carrier_type" class="invalid-feedback">
                                    <span v-if="!$v.carrier_type.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.report_id" class="invalid-feedback">{{ errors.report_id[0] }}</div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">MM/YYYY:<span class="required">*</span></label>
                                <div class="cl"></div>
                                <Datepicker :minimumView="'month'" :maximumView="'month'" placeholder="MM/YYYY" v-model="monthyear"
                                :format="dispString" :highlighted="state.highlighted" :disabled-dates="state.disabledDates" @input="dispDate"/>
                                <div v-if="submitted && $v.monthyear.$error && !errors.monthyear" class="invalid-feedback">
                                    <span v-if="!$v.monthyear.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.month_year" class="invalid-feedback positionstatic">{{ errors.month_year[0] }}</div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="fl">
                                <button class="fl mt20 mr10" type="button" @click="savePayment"  tabindex="18">Submit</button>
                                <button class="fr mt20" type="button" tabindex="18" @click="clearAll()" >Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cl"></div>
                 <div class="mt10 mr10"  v-if="message!=''"> {{message}}
                         <a class="summarylink" @click="generateSummary()"><strong> -  Click here to view report summary</strong></a>
                </div>

                <!-- <div class="mt10 mr10" v-if="message!=''">{{message}}
                    <a  v-bind:href="csvlink" download><strong> - Click here to Download</strong></a>
                </div> -->
                <div class="mt20"  v-if="disppage == true &&  paymentData!=''">
                    <div class="tablesection">
                        <div class="tablebox">
                            <table>
                                <thead>
                                <tr  @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                                    <th class="width30 textcenter">No.</th>
                                    <th class="handpointer"
                                        v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)"
                                        :class="column.class" >
                                        <span  v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                                        <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                        <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                                    </th>


                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(list,i) in paymentData" :key="i" @mouseover="selectItem(list)" @mouseleave="unSelectItem(list)" v-bind:class="{ 'trselected':list === selectedItem }">
                                        <td class="width30 textcenter"> {{ perpage * (current_page - 1) + i + 1}}</td>
                                        <td class="width100 textleft">  {{ list.ftp_name    }}   </td>
                                        <td class="width70 textcenter">  {{ list.start_date     }}   </td>
                                        <td class="width70 textcenter">  {{ list.end_date       }}   </td>
                                        <td class="width70 textcenter">  {{ list.generated_date }}   </td>
                                        <td  class="width70 textcenter">
                                             {{ list.ftp_transfer_date }}
                                        </td>
                                         <td class="width70 textcenter">
                                        <a href="#"  @click="downloadFile(list.report_id, list.month_year)"  class="tooltipbox"> <i class="fas fa-download"></i> <span>  Download File</span></a>
                                    </td>


                                     <td class="width70 textcenter">
                                        <a href="#" @click="verifyReort(list.month_year)" class="tooltipbox">
                                            <i class="fas fa-check-double"></i> <span>Verfiy </span></a>
                                        </td>

                                    <td class="width70 textcenter" v-if="list.ftp_send=='Y'">
                                        <a href="#" @click="sendToFtp(list.report_id,list.start_date,list.end_date)" class="tooltipbox"> <i class="fas fa-play"></i> <span>Send to FTP</span></a>
                                    </td>

                                    <td v-else class="width70 textcenter">
                                        {{ 'N/A' }}
                                    </td>


                                    </tr>
                                    <tr v-if="this.noData != ''">
                                        <td colspan="5">
                                            <div class="warning">
                                                <div class="positive-error" style="border:none">
                                                    <p>{{noData}}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="cl"></div>
                    <div class="paginationsecion" v-if="disppage">
                        <div class="cl"></div>
                        <div class="shorting">
                            <label>Show</label>
                            <div class="shorbox">
                                <div class="selectbox iinnoicon-down_arrow">
                                    <select v-model="entries" @change="showEntries()" class="form-control">
                                        <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i" :value="pp">{{pp}}</option>
                                    </select>
                                </div>
                            </div>
                            <span>entries</span>
                        </div>

                         <div class="paginationbox" v-if="this.total_page > 1">
                                <ul class="pagination">
                                    <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                                    <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"
                              v-show="(i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1) || ((i==9&&current_page>9)||i==total_page-1)">
                              <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                              <a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                                    <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                                </ul>
                            </div>

                    </div>
                </div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <!-- Confirmation Popup -->
    <div v-if="showPopup" style="position: absolute;" class="popupbox">
        <div class="exportbox memberrecharge">
        <div class="formtitle"> <div class="logo mr10"> <div class="iinnoicon-cog">  </div> </div>
            <div class="popuptitletext pl10">Carrier Report</div>
            <a class="fr popupclose handpointer" @click="showPopup=false">X</a></div>
            <div class="formpart">
                <p class="textcenter"><strong>{{popupText}} {{'do you want to regenarate ?'}}</strong></p>
                <button type="button" class="fr mr10" @click="showPopup=false"> Cancel</button>
                <button type="button" class="fr mr10" @click="savePayment('Y')">Yes</button>
            </div>
        </div>
        <div class="overlay"></div>
    </div>

     <!--  commission verify with transaction  popup -->
    <div v-if="showVerifyPopup" style="position: absolute;" class="popupbox">

        <div class="exportbox memberrecharge">
        <div class="formtitle"> <div class="logo mr10"> <div class="iinnoicon-cog">  </div> </div>
            <div class="popuptitletext">Payment Report Verification</div>
            </div>
            <div class="formpart">

                  <!-- step - 1  -->
                <div v-if="verifystep1==true">
                <div class="tableloader">
                      <img src="/images/icons/logo2.svg">
                </div>
                <p class="text-center verifyText">Please Wait....  We are verifying payment data..</p>
                </div>

                 <!-- step - 2 -->
                <div  v-if="verifystep2==true">
                     <p class="text-center verifyText"> Total No. of records are Available: {{verifyTotalrecord}} </p>

                <div class="text-center">
                   <p class="verifyText">
                         <i class="far fa-check-circle"></i>  Payment report verification is completed.
                     </p>
                </div>
                </div>

                 <!-- step - 3 -->
                 <div v-if="verifystep3==true">
                <div class="text-left">
                     <p class="verifyText"> Verify Report Summary: </p>
                      <div class="tablesection">
                        <div class="tablebox">
                      <table>
                        <tbody>

                            <tr>
                                <td class="text=left">{{ this.qa_title }} </td>
                                <td>{{ this.totalqa }}</td>
                            </tr>

                            <tr>
                                <td class="text=left">{{ this.verify_title }} </td>
                                <td>{{ this.totalpaymentreport }}</td>
                            </tr>

                            <tr>
                                <td class="text=left">{{ this.qa_column }} </td>
                                <td>{{ this.expectedAmt }}</td>
                            </tr>
                            <tr>
                                <td class="text=left">{{ this.verify_column }} </td>
                                <td>{{this.totalAmt}}</td>
                            </tr>

                             <tr>
                                <td class="text=left">{{ this.rate_text }} </td>
                                <td>{{this.rate_Amt}}</td>
                            </tr>

                        </tbody>
                      </table>
                        </div>
                      </div>
                </div>

                <div class="cl"></div>
                 <p class="fl cancelbtn" v-if="verifylink !=''">
                    <strong> <a  :href="verifylink" download class="handpointer">Click here to download verification file </a>     </strong>
                </p>
                    <div class="fr">
                        <button class="fl cancelbtn mr10" type="button" tabindex="2" @click="clearverifyData()" >Close</button>
                    </div>
                </div>


                <!-- old code
                 <p class="textcenter" v-if="verifymsg!== null">
                        {{ verifymsg }}
                 </p>
                <div class="verifylink" v-if="verifylink !=''">
                    Download File -
                    <a :href="verifylink" target="_blank" download><strong class="handpointer"> Click here to download </strong></a>
                    </div> -->
            </div>
        </div>
        <div class="overlay"></div>
    </div>

     <!-- report generated summary  -->
    <div v-if="summaryPopup" style="position: absolute;" class="popupbox">
        <div class="exportbox memberrecharge">
        <div class="formtitle"> <div class="logo mr10"> <div class="iinnoicon-cog">  </div> </div>
            <div class="popuptitletext">Generated Payment Report Summary </div>
            <a class="fr popupclose handpointer" @click="closeSummary()">X</a></div>
            <div class="formpart text-center">
                 <div class="text-left">
                      <div class="tablesection">
                        <div class="tablebox">
                      <table>
                        <tbody>
                            <tr>
                                <td class="text=left">Total Inserted Records:  </td>
                                <td> {{this.totalInserted}}</td>
                            </tr>
                            <tr>
                                <td class="text=left">Total Exported Records:  </td>
                                <td>{{this.totalExported}}</td>
                            </tr>
                            <tr>
                                <td class="text=left">Total of {{ this.columnNme }}:</td>
                                <td>{{this.exportAmt}} </td>
                            </tr>
                        </tbody>
                      </table>
                        </div>
                      </div>
                </div>

                <div class="cl"></div>
                 <p class="fl cancelbtn">
                    <strong> <a v-bind:href="csvlink" download  class="handpointer">Click here to download report file </a>
                    </strong>
                </p>
                <button type="button" class="fr mr10 mt15" @click="closeSummary()"> Close</button>
            </div>
        </div>
        <div class="overlay"></div>
    </div>

       <!-- Send to FTP Popup -->
    <div v-if="ftpPopup" style="position: absolute;" class="popupbox">
        <div class="exportbox memberrecharge">
        <div class="formtitle"> <div class="logo mr10"> <div class="iinnoicon-cog">  </div> </div>
            <div class="popuptitletext">Send File to Carrier FTP </div>
            <a class="fr popupclose handpointer" @click="ftpPopup=false">X</a></div>
            <div class="formpart text-center">
                <p class="errorMessage" v-html=this.ftpMessage>
                </p>
             <div  v-if="ftpLink !=''">
                    <a :href="ftpLink" target="_blank" download><strong class="handpointer"> Click here to download file </strong></a>
             </div>

                <button type="button" class="fr mr10" @click="ftpPopup=false"> Cancel</button>
            </div>
        </div>
        <div class="overlay"></div>
    </div>

</div>
</template>
<script>

import { required } from "vuelidate/lib/validators";
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
export default {
    components: {
        Datepicker
    },
    data() {
        return {
            opentab     : "ReportName",
            errors      : "",
            submitted   : "",
            dispString  : "",
            carrier_type: "",
            monthyear   : "",
            message     : "",
            csvlink     : "",
            entries     : "",
            noData      : "",
            popupText   : "",
            total_page  : 0,
            current_page: 1,
            perpage     : 0,
            showPopup   : false,
            active      : false,
            selectedItem: false,
            disppage    : false,
            sHead       : false,
            showVerifyPopup: false,
            ftpPopup: false,
            ftpMessage: "",
            ftpLink:"",
            verifystep1  : false,
            verifystep2  : false,
            verifystep3  : false,
            verifyTotalrecord:0,
            expectedAmt:0,
            totalAmt:0,
            totalqa:0,
            qa_title:'',
            verify_title:'',
            qa_column:'',
            verify_column:'',
            rate_text:'',
            rate_Amt:0,
            totalpaymentreport:0,
            verifylink   : '',
            paymentData : [],
            paymentList : [],
            summaryPopup:false,
            totalInserted:"",
            totalExported:"",
            exportAmt:"",
            columnNme:"",
            state         : {
                disabledDates: {
                    to: new Date(2021, 4, 1),
                    from: new Date()
                }
            },
            columns: [
                { text: "FTP Name"   , value: "ftp_name"   , class:"textleft"  },
                { text: "Start Date"    , value: "start_date"    , class:"textcenter"},
                { text: "End Date"      , value: "end_date"      , class:"textcenter"},
                { text: "Generated Date", value: "generated_date", class:"textcenter"},
                 { text: "FTP Transfer Date", value: "ftp_transfer_date", class:"textcenter"},
                { text: "Download File", value: "download_file", class:"textcenter"},
                { text: "Verify", value: "verify", class:"textcenter"},
                { text: "Send to FTP", value: "action", class:"textcenter"},
            ],

        }
    },
    validations: {
        carrier_type    : { required },
        monthyear       : { required },
    },
    mounted(){
        this.getCarriersList();
    },
    methods: {
        //Check Box functionality
        selectItem (item) {
            this.selectedItem = item
        },
        unSelectItem () {
            this.selectedItem = false
        },

        selectHead(val)
        {
            this.active =val;
        },
        /* Accordion  */
        toggleSection(tab) {
            if(this.opentab==tab){
                this.opentab='';
            }
            else{
                this.opentab=tab;
            }
        },
        dispDate()
        {
            if(this.errors.month_year)
                this.errors.month_year='';
            var year  = moment(this.monthyear).format("YYYY");
            var month = moment(this.monthyear).format("MM");
            this.dispString = month+"/"+year;
        },


        /* Get Payment Data */
        getPaymentData(pageno = false, perPage=false, isReload=false)
        {

            if(isReload == false)
            {
                this.message = '';
            }

            var surl = '';
            if(perPage != false)
            {
                surl = `api/generatereportlist/${this.carrier_type}?page=${pageno}&per_page=${perPage}`;
            }
            else if(perPage!= false && pageno != false)
            {
                surl = `api/generatereportlist/${this.carrier_type}?page=${pageno}&per_page=${perPage}`;
            }
            else
            {
              surl =   `api/generatereportlist/${this.carrier_type}?page=${pageno}`;
            }


            this.paymentData=[];
            this.$store.dispatch("ajax", {
                method  : "post",
                //url     : `api/generatereportlist/${this.carrier_type}?page=`+pageno,
                url : surl,
                loader  : "section",
            })
            .then((response) => {
                if (response.data.data)
                {
                    this.paymentData    = response.data.data.data;
                    this.entries        = response.data.data.per_page;
                    this.total_page     = response.data.data.last_page;
                    this.perpage        = response.data.data.per_page;
                    this.noData         = '';
                    this.disppage       = true;
                }
                else
                {
                    this.paymentData    = [];
                    this.total_page     = 0;
                    this.disppage       = false;
                    this.noData         = response.data.message;
                }

            })
            .catch((error) =>
            {
                this.paymentData    = [];
                this.total_page     = 0;
                this.disppage       = false;
                this.noData         = error.response.data.message;
            })

        },

        // pagination
        pagination(next_page)
        {
                this.getPaymentData(next_page,false,true)
                this.current_page = next_page;
        },


        //show entries data
        showEntries()
        {
            if(this.entries<=100)
            {
                 this.getPaymentData(false,this.entries,true);

               // this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } });
            }
        },

        /* Get Carrier List */
        getCarriersList()
        {
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/getpaymentreportlist",
                loader  : "page",
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.paymentList  = response.data.data;
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },
        /* save payment */
        savePayment(str)
        {
            this.submitted  = true;
            this.errors     = [];
            var monyear     = "";
            if(this.monthyear)
            {
                var year    = moment(this.monthyear).format("YYYY");
                var month   = moment(this.monthyear).format("MM");
                    monyear = month+"-"+year;
            }
            else
                monyear     = "";
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch("ajax", {
                method  : "post",
                url     :  "api/generatepaymentreport",
                loader  : "section",
                params  :  {
                    report_id       : this.carrier_type,
                    month_year      : monyear,
                    is_regenerate   : str=='Y'?'Y':'',
                }
            })
            .then((response) => {

                    this.message    = response.data.message;
                    this.csvlink    = response.data.data.csv_url;
                    this.totalInserted = response.data.data.saveCount;
                    this.totalExported = response.data.data.exportCount;
                    this.exportAmt = response.data.data.totalAmount;
                    this.columnNme = response.data.data.column_name;
                    this.showPopup  = false;
                    this.getPaymentData(false,false,true);
            })
            .catch((error) => {
                if (error.response.data.data)
                {
                    if(error.response.data.data.report_id)
                    {
                        this.popupText  = error.response.data.data.report_id;
                        this.showPopup  = true;
                    }
                    else
                    {
                        this.errors     = error.response.data.data;
                        this.showPopup  = false;
                    }
                }
            });
        },

        /* Clear All */
        clearAll()
        {
            this.carrier_type = "";
            this.monthyear    = "";
            this.errors       = "";
            this.message      = "";
            this.popupText    = "";
            this.disppage     = false;
            this.submitted    = false;
        },

        /*send file to FTP*/
        sendToFtp(report_id,from_date,to_date)
        {
            var baseUrl = window.location.origin;

            if(baseUrl=='http://localhost:8080' || baseUrl=='https://vin002accountreports.innovainfotech.com/')
            {
                //  this.ftpPopup = true;
                //  this.ftpMessage  = "The file can not transfer to the test server.";

                 this.$store.state.error_message= "The file can not transfer to the test server.";

            }
           else{
                    let replaceFromDate = from_date.replaceAll("/", "-");
                    let replaceToDate = to_date.replaceAll("/", "-");

                    this.$store.dispatch("ajax", {
                        method  : "post",
                        url     :  "api/propsperityreportsendtoftp",
                        loader  : "section",
                        params  :  {
                            report_id      : report_id,
                            from_date      : replaceFromDate,
                            to_date        : replaceToDate,
                        }
                    })
                    .then((response) => {

                        this.ftpPopup = true;
                        if (response.data.status == 1)
                        {
                            this.ftpMessage  = response.data.message;
                            this.ftpLink = response.data.data.file_url;
                        }
                    })
                    .catch((error) => {
                            this.ftpMessage  = error.response.message;
                            this.errors =  error.response.status;
                    })
              }

        },

        // download file
        downloadFile(reportId,mmyy)
        {
             this.$store.dispatch("ajax", {
                method  : "post",
                url     :  "api/exportreport",
                loader  : "section",
                params  :  {
                    report_id       : reportId,
                    month_year      : mmyy,
                }
            })
            .then((response) => {

                   if(response.data.data)
                   {
                       if(response.data.data.URL!="")
                           window.location.href = response.data.data.URL;
                       else
                           this.$store.state.error_message=response.data.message;
                   }
                   else{
                       this.$store.state.error_message=response.data.message;
                   }
            })
            .catch((error) => {
                 this.errors =  error.status;
                 this.$store.state.error_message= this.errors;
            });
        },

        // verify report
        verifyReort(monthYear)
        {
            this.showVerifyPopup = true;
            this.verifystep1 = true;

            this.$store.dispatch("ajax",
            {
                method  : "post",
                url     : "api/verifypaymentreport",
                params  : {
                    report_id  : this.carrier_type,
                    month_year : monthYear,
                }
            }).then((response) =>
             {
                setTimeout(function () {
                        this.verifystep1 = false;
                        this.verifystep2 = true;
                    }.bind(this), 2000)



                 //this.verifymsg = response.data.message;
                if (response.data.status == 1)
                {


                    this.verifyTotalrecord  =  response.data.data.total_record;
                    this.verifylink =  response.data.data.fileUrl;
                    this.expectedAmt = response.data.data.expected_amount;
                    this.totalAmt = response.data.data.actual_amount;
                    this.totalqa =  response.data.data.total_record;
                    this.qa_title = response.data.data.qa_total_text;
                    this.verify_title = response.data.data.verify_total_text;
                    this.qa_column = response.data.data.qa_column;
                    this.verify_column = response.data.data.verify_column;
                    this.totalpaymentreport = response.data.data.total_payment_record;
                    this.rate_text = response.data.data.rateproduct_total_text;
                    this.rate_Amt = response.data.data.rateproduct_amount;



                      setTimeout(function () {
                        this.verifystep2 = false;
                        this.verifystep3 = true;
                    }.bind(this), 5000)


                }

                if (response.data.status == 0)
                {
                     this.verifymsg =  response.data.message;

                    setTimeout(function () {
                        this.verifystep1 = false;
                        this.verifystep2 = false;
                        this.verifystep3 = false;

                        this.showVerifyPopup = false;

                      }.bind(this), 5000);



                }

                })
                .catch((error) => {
                if (error.response.data.status == 0) {
                     this.verifymsg = error.response.data.data;

                      this.verifystep1 = false;
                        this.verifystep2 = false;
                        this.verifystep3 = false;

                        this.showVerifyPopup = false;
                }
            });
        },
        clearverifyData()
        {
            this.verifystep1 = false;
            this.verifystep2 = false;
            this.verifystep3 = false;
            this.showVerifyPopup = false;
            this.verifyTotalrecord=0;
            this.expectedAmt = 0;
            this.totalAmt = 0;
            this.qa_title = '',
            this.verify_title ='',
            this.qa_column = '';
            this.verify_column = '';
            this.rate_text='',
            this.verifylink = '';
            this.totalqa = 0;
            this.totalpaymentreport = 0;
            this.rate_Amt = 0;

        },

         generateSummary(){
            this.summaryPopup = true;
        },

        closeSummary(){

            // this.totalInserted = "";
            // this.totalExported = "";
            // this.exportAmt = "";
            // this.columnNme = "";
            this.summaryPopup = false;

        }
    }

}
</script>
<style scoped>
.popupbox .logo {
    height: auto;
    padding: 5px 0;
}

.popupbox .logo div {
    line-height: 30px;
}

.popuptitletext {
    line-height: 40px;
}
.verifylink{
     text-align: center;
}

.verifyText{
    font-size: 15px;
    font-weight: 700;
}

.exportbox{
    top:10%
}

.cancelbtn
{
    margin-top:20px;
}

.summarylink{
 cursor:pointer;
}

</style>