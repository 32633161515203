import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuelidate from 'vuelidate';
import VueCookies from 'vue-cookies';
import jwt from "jsonwebtoken";
// import VueGtag from "vue-gtag";
//import '@datadog/browser-rum/bundle/datadog-rum';
store.state.ajax.apiURL = process.env.VUE_APP_API_URL;
Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.config.productionTip = false

Vue.directive('draggable', {
    bind: function(el) {
        el.style.position = 'absolute';
        var startX, startY, initialMouseX, initialMouseY;

        function mousemove(e) {
            var dx = e.clientX - initialMouseX;
            var dy = e.clientY - initialMouseY;
            el.style.top = startY + dy + 'px';
            el.style.left = startX + dx + 'px';
            return false;
        }

        function mouseup() {
            document.removeEventListener('mousemove', mousemove);
            document.removeEventListener('mouseup', mouseup);
        }

        el.addEventListener('mousedown', function(e) {
            startX = el.offsetLeft;
            startY = el.offsetTop;
            initialMouseX = e.clientX;
            initialMouseY = e.clientY;
            document.addEventListener('mousemove', mousemove);
            document.addEventListener('mouseup', mouseup);
            return false;
        });
    }
});
Vue.prototype.$companyTitle = "IN002";

new Vue({
    data() {
        return {
            searchShow      : false,
            currentLeftMenu : 0,
            page_type       : 'username',
            page_loader     : false,
            section_loader  : false,
            logindata       : '',
            quicknotepopup  : false,
            lastuserlogin   : '',
        }
    },
    beforeCreate() {
        this.lastuserlogin = VueCookies.get(window.location.hostname.substring(10, 4) + 'accountlastLogin');
        this.logindata = VueCookies.get(window.location.hostname.substring(10, 4) + 'accountcookie');
        if (this.logindata != null && this.logindata != undefined && this.logindata != '' && this.logindata != 'null') {

            jwt.verify(this.logindata, this.$store.state.ajax.keys, function(err, decoded) {
                if (err != null) {
                    VueCookies.set(window.location.hostname.substring(10, 4) + "accountcookie", '', "12h");
                } else {
                    store.state.ajax.org_user = decoded.logindata;
                }
            });
        }
        if (this.$store.state.ajax.org_user.access_token != '' && this.$store.state.ajax.org_user.access_token != null) {
            if(this.$route.name == 'userlogin')
                this.$router.push("/dashboard");
        } 
        else {
                this.$router.push("/")
                if (this.$route.name == 'PageNotFound') {
                    this.$router.push("/404");
                }
        }
    },
    watch: {
        $route() {
            if (this.$store.state.ajax.org_user.access_token == '' && this.$route.name != 'ForgotPassword') {
                if (this.lastuserlogin == 'associatelogin') {
                    this.$router.push("/associate");
                }
                else{
                    if(this.$route.path!='/dashboard'){
                        this.$router.push("/dashboard");
                    }
                }
            }
        }
    },
    router,
    store,
    render: h => h(App)
}).$mount('#app')