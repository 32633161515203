import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/Layout.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import SetupCommissionList from '../views/Commission/SetupCommission/SetupCommissionList.vue'
import ReleaseCommission from '../views/Commission/ReleaseCommission/ReleaseCommission.vue'
import AddSetupCommission from '../views/Commission/SetupCommission/AddSetupCommission.vue'
import AddDistribution from '../views/Commission/Distribution/AddDistribution.vue'
import StateGroup from '../views/Commission/StateGroup/StateGroupList.vue'
import AddStateGroup from '../views/Commission/StateGroup/AddStateGroup.vue'
import DistributionOption from '../views/Commission/DistributionOption/DistributionOptionList.vue'
import AddDistributionOption from '../views/Commission/DistributionOption/AddDistributionOption.vue'
import Vendor from '../views/Vendor/Vendor.vue'
import Carrier from '../views/Carrier/Carrier.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'userlogin',
    component: login
  },
  {
      path: '/user',
      name: 'userlogin',
      component: login
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/commission/stategroup',
    name: 'commission',
    component: StateGroup
  },
  {
    path: '/commission/addstategroup',
    name: 'commission',
    component: AddStateGroup
  },
  {
    path: '/commission/setupcommissionlist',
    name: 'commission',
    component: SetupCommissionList
  },
  {
    path: '/commission/addsetupcommission',
    name: 'commission',
    component: AddSetupCommission
  },
  {
    path: '/commission/releasecommission',
    name: 'commission',
    component: ReleaseCommission
  },
  {
    path: '/commission/distribution',
    name: 'commission',
    component: AddDistribution
  },
  {
    path: '/commission/distributionoption',
    name: 'commission',
    component: DistributionOption
  },
  {
    path: '/commission/adddistributionoption',
    name: 'commission',
    component: AddDistributionOption
  },
  {
    path: '/carrier',
    name: 'carrier',
    component: Carrier
  },
  {
    path: '/vendor',
    name: 'vendor',
    component: Vendor
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
