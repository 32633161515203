<!--
 * Title      : Save commission distribution option
 * Developer  : Ankit Javiya
 * Description: This page is designed for distribution option add/edit
 *
 -->
<template>

<div class="pagesection">
    <div class="leftsection">
    </div>
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle"><div class="iinnoicon-sate_group"></div>{{$route.query.option_id?'Edit Commission Schedule':'Add Commission Schedule'}}</div>
            <div class="quickmenubox toprighticon">
            </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <CommissionLinks />
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
               <div class="member-quickprofilebox memberquickbox profileLinkCard">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="formpart">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="tab-formtitle iinnoicon-down_arrow activemode">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Commission Schedule</a>
                                            </div>
                                            <div class="cl"></div>
                                        </div>
                                    </div>
                                    <div class="row maxwidth1400 collapse in" id="collapse1" aria-expanded="false">
                                        <div class="col-md-3 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Company:<span class="required">*</span></label>
                                                    <div class="selectbox iinnoicon-down_arrow">
                                                        <select class="form-control" v-model="companyName" tabindex="1"
                                                        :class="{ 'is-invalid': submitted && $v.companyName.$error || errors.agency_id}"
                                                        @input="errors.agency_id?errors.agency_id='':''">
                                                        <option value="">Select Company</option>
                                                        <option value="0">All</option>
                                                        <option v-for="(com,i) in companyList" :key="i" :value="com.agency_id">{{com.name}}</option>
                                                    </select>
                                                </div>
                                                <div v-if="submitted && $v.companyName.$error && !errors.agency_id" class="invalid-feedback">
                                                    <span v-if="!$v.companyName.required"> {{$store.state.Messages.require}}</span>
                                                </div>
                                                <div v-if="errors.agency_id" class="invalid-feedback">{{ errors.agency_id[0] }}</div>
                                            </div>
                                      </div>

                                    <div class="col-md-3 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Transaction Type:<span class="required">*</span></label>
                                            <div class="selectbox iinnoicon-down_arrow">
                                                <select class="form-control" v-model="transType" tabindex="2"
                                                @change="errors.transType?errors.transType='':'',getduration($event)"
                                                :class="{ 'is-invalid': submitted && $v.transType.$error || errors.transType}">
                                                <option value="">Select Transaction Type</option>
                                                <option v-for="(value, name) in transTypeList" :key="name" :value="name">{{value}}</option>
                                            </select></div>
                                            <div v-if="submitted && $v.transType.$error && !errors.transType" class="invalid-feedback">
                                                <span v-if="!$v.transType.required"> {{$store.state.Messages.require}}</span>
                                            </div>
                                            <div v-if="errors.transType" class="invalid-feedback">{{errors.transType[0]}}</div>
                                        </div>
                                    </div>

                                        <div class="col-md-3 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Commission From Date:<span class="required">*</span></label>

                                                <input type="date" tabindex="3" placeholder="MM-DD-YYYY" maxlength="50" class="form-control" v-model="commFromDate">

                                            <div v-if="submitted && $v.commFromDate.$error && !errors.commFromDate" class="invalid-feedback">
                                                <span v-if="!$v.commFromDate.required"> {{$store.state.Messages.require}}</span>
                                            </div>
                                            <div v-if="errors.commFromDate" class="invalid-feedback">{{errors.commFromDate[0]}}</div>
                                            </div>
                                        </div>



                                        <div class="col-md-3 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Duration:<span class="required">*</span></label>
                                                <div class="selectbox iinnoicon-down_arrow">
                                                <select class="form-control" v-model="duration" tabindex="2" @change="errors.duration?errors.duration='':''"
                                                    :class="{ 'is-invalid': submitted && $v.duration.$error || errors.duration}">
                                                    <option value="">Select Duration</option>
                                                    <option v-for="(value, name) in durationList" :key="name" :value="name">{{value}}</option>

                                                </select></div>
                                                <div v-if="submitted && $v.duration.$error && !errors.duration" class="invalid-feedback">
                                                    <span v-if="!$v.duration.required"> {{$store.state.Messages.require}}</span>
                                                </div>
                                                <div v-if="errors.duration" class="invalid-feedback">{{errors.duration[0]}}</div>
                                            </div>
                                        </div>



                                        <div class="cl"></div>
                                        <div class="col-md-12">
                                            <div class="fr">
                                                <button class="fr mt20" type="button" tabindex="5" @click="$router.push('/commission/distributionoption')">Cancel</button>
                                                <button class="fl mt20 mr10" type="button" tabindex="4" @click="saveDistOption()">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cl"></div>
                        </div>
                    </form>
                </div>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import moment  from 'moment';
import CommissionLinks from "../CommissionLinks.vue";
import functionmixins from "../../../mixins/functionmixins.js";
import { required } from "vuelidate/lib/validators";
import vClickOutside from 'v-click-outside';

export default({
    mixins: [functionmixins],
     components:{
       CommissionLinks
    },
    data() {
        return {
            errors          : "",
            companyName     : "",
            transType       : "",
            duration        : "",
            commFromDate    : "",
            submitted       : "",
            search          : false,
            companyList     : [],
            transTypeList   : [],
            durationList    : [],
        }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    validations: {
        companyName     : { required },
        transType       : { required },
        commFromDate    : { required },
        duration        : { required },
    },

    mounted()
    {
        this.getCompanyList();
        this.getTransactionTypeList();
      //  this.getDurationList();

        if(this.$route.query.option_id)
        {
            this.getCommissionOptionData();
        }


    },
    methods: {

        /* get duration base on transaction typer */
        getduration($event){

            var val = $event.target.value;
            this.getDurationList(val);

        },

        /* Get Company List */
        getCompanyList()
        {
            this.companyList    = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "section",
                url     : "api/getallcompanylist"
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.companyList = response.data.data;
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },

        /* Get Transaction type List */
        getTransactionTypeList()
        {
            this.transTypeList    = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "section",
                url     : "api/gettranstypelist"
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.transTypeList = response.data.data;
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },

        /*Get Commission option Data */
        getCommissionOptionData()
        {

            this.$store.dispatch("getajax",
            {
                method    : "get",
                url       : `api/commdistroption/${this.$route.query.option_id}`,
                loader    : "page",
            })
            .then((response) =>{
                if(response.data.data)
                {
                    this.companyName   = response.data.data.agency_id;
                    this.transType     = response.data.data.transaction_include;
                    this.commFromDate  = this.dateFrontend(response.data.data.comm_distr_from_date);

                    this.getDurationList(this.transType);

                    this.duration      = response.data.data.duration;

                }
            })
            .catch((error) =>{
                this.errors = error.response.data.data;
            })
        },

        /* Get Duration List */
        getDurationList(transType)
        {
            this.durationList    = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "section",
                url     : "api/getalldurationlist"
            })
            .then((response) => {
                if(response.data.data)
                {
                    if(transType =='NewBusiness'){
                            this.durationList = response.data.data.new;
                    }

                    else if(transType =='Renewal'){
                            this.durationList = response.data.data.renewal;
                    }
                    else{
                             this.durationList    = [];
                    }

                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },

        checkIsValid () {
            this.submitted = true;
        },

        /* Save distribution option   */
        saveDistOption()
        {
             this.submitted = true;

              this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.errors    = [];
            var method     = "";
            var url        = "";

            if(this.$route.query.option_id)
            {
                method     = "put";
                url        = "api/commdistroption/"+this.$route.query.option_id;
            }
            else
            {
                method     = "post";
                url        = "api/commdistroption"
            }

             this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.$store.dispatch("ajax",
            {
                method  : method,
                url     : url,
                params  : {
                    agency_id  : this.companyName.toString(),
                    transaction_include  : this.transType,
                    comm_distr_from_date  : this.dateBackend(this.commFromDate),
                    duration  : this.duration,

                }
            })
            .then((response) =>
            {
                if (response.data.status == 1) {
                    this.$store.state.success_message=response.data.message;
                    this.$router.push("/commission/distributionoption");
                }
                })
                .catch((error) => {
                if (error.response.data.status == 0) {
                   // this.errors = error.response.data.data;
                     this.$store.state.error_message= error.response.data.message;
                }
            });

        },

        clearAll()
        {
            this.companyName   = "";

        },
        /*  Date convertion from backend to frontend  */
        dateFrontend(dt) {
             return moment(dt, "MM-DD-YYYY").format("YYYY-MM-DD");
        },

        /* Date conversion from frontend to backend */
        dateBackend(dt) {
             return moment(dt, "YYYY-MM-DD").format("MM-DD-YYYY");
        },
    }
})
</script>
<style scoped>
ul.temp1 li {
    float: left !important;
    width: 75px;
}

ul.temp1 li input {
    position: relative;
    top: -5px;
}

ul.temp1 {
    list-style: none;
}

</style>