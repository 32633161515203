<!--
 * Title      : Dashboard - Right Side
 * Developer  : Ankit Javiya
 * Description: This page is designed for Dashboard Page
 *
 -->
<template>
  <div class="col-md-7">
    <div
      class="panel-group  memberprofiletab dashboard-right-panel"
      id="accordion"
      v-if="navigationData.length"
    >
      <div
        class="panel mb20"
        v-for="(navigation, i) in navigationData"
        :key="i"
        v-show="navigation.module_type == 'dashboardright'"
      >
        <div class="formpart" v-if="navigation.module_type == 'dashboardright'">
          <div class="row">
            <div class="col-md-12">

            <div v-if="navigation.module_name=='Schedule Vendor Distribution'"
                class="tab-formtitle iinnoicon-down_arrow"
                :class="{ activemode: tab == navigation.module_name }">
                <a
                     @click="TabChange(navigation.module_name)"
                  >{{ navigation.module_name }}
                  <span v-if="navigation.scheduleCount > 0" class="countColor"
                    >({{ navigation.scheduleCount }})</span>
                </a>
            </div>

              <div v-if="navigation.module_name=='Schedule Payment Report'"
              class="tab-formtitle iinnoicon-down_arrow"
                              :class="{ activemode: tab2 == navigation.module_name }">
                              <a
                                  @click="TabChange2(navigation.module_name)"
                                >{{ navigation.module_name }}
                                <span v-if="navigation.scheduleCount > 0" class="countColor"
                                  >({{ navigation.scheduleCount }})</span>
                              </a>
              </div>

              <div v-if="navigation.module_name=='Schedule Commission Distribution'"
              class="tab-formtitle iinnoicon-down_arrow"
                              :class="{ activemode: tab3 == navigation.module_name }">
                              <a
                                  @click="TabChange3(navigation.module_name)"
                                >{{ navigation.module_name }}
                                <span v-if="navigation.scheduleCount > 0" class="countColor"
                                  >({{ navigation.scheduleCount }})</span>
                              </a>
              </div>

            </div>
          </div>
          <div class="cl"></div>
          <!-- upcoming vendor -->
          <div
            class="row"
            v-if="
              (tab == 'Schedule Vendor Distribution' ||
                tab == 'Schedule Vendor Distribution') &&
                (navigation.module_name == 'Schedule Vendor Distribution' ||
                  navigation.module_name == 'Schedule Vendor Distribution')
            "
          >
            <div class="col-md-12">
              <div class="dashboard-border">
                <div class="todolist-title">
                  <div
                    class="paginationsecion fr mnop widthauto"
                    v-if="total_page > 1"
                  >
                    <ul class="pagination mnop">
                      <li
                        class="page-item"
                        v-bind:class="{ disabled: current_page === 1 }"
                        v-show="current_page !== 1"
                      >
                        <a
                          class="page-link paginationbtn"
                          href="#"
                          @click.prevent="pagination(current_page - 1)"
                          >Previous</a
                        >
                      </li>
                      <li
                        class="page-item"
                        v-bind:class="{ active: current_page == index + 1 }"
                        v-for="(i, index) in Number(total_page)"
                        :key="index"
                      >
                        <a
                          class="page-link"
                          v-if="
                            i == 1 ||
                              i == 2 ||
                              i == 3 ||
                              i == 4 ||
                              i == 5 ||
                              i == 6 ||
                              i == 7 ||
                              i == 8 ||
                              i == total_page ||
                              i == current_page ||
                              i == current_page - 1 ||
                              i == current_page + 1
                          "
                          href="#"
                          @click.prevent="pagination(index + 1)"
                          >{{ index + 1 }}</a
                        ><a
                          class="page-link"
                          v-else
                          v-show="
                            (i == 9 && current_page > 9) || i == total_page - 1
                          "
                          >...</a
                        >
                      </li>
                      <li
                        class="page-item"
                        v-bind:class="{ disabled: current_page == total_page }"
                        v-show="current_page !== total_page"
                      >
                        <a
                          class="page-link paginationbtn"
                          href="#"
                          @click.prevent="pagination(current_page + 1)"
                          >Next</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="cl"></div>
                <div class="salesgrap">
                  <div class="cl"></div>
                  <transition-group
                    v-if="Object.keys(vendorDistrList).length > 0"
                  >
                    <div
                      class="todolistbox"
                      v-for="(task, i) in vendorDistrList"
                      :key="i"
                    >
                      <ul>
                         <!-- @click="verifycheckbox()" -->
                        <li>
                          <label :for="task">
                             <input :id="task"
                              v-if="task.includes('Done')" checked=true    type="checkbox"  placeholder="" :v-model="task">
                             <input :id="task"   v-else  type="checkbox"  placeholder="" :v-model="task">

                           <p  v-if="task.includes('Done')" v-html="task.slice(0,-7)"></p>
                           <p   v-else v-html="task"></p>
                            </label>
                        </li>
                      </ul>
                    </div>
                  </transition-group>
                  <p v-else>No schedule available for vendor distribution!</p>
                </div>
              </div>
            </div>
          </div>

          <!-- upcoming payment -->
          <div
            class="row"
            v-if="
              tab2 == 'Schedule Payment Report' &&
                navigation.module_name == 'Schedule Payment Report'
            "
          >
            <div class="col-md-12">
              <div class="dashboard-border">
                <div class="todolist">
                  <div
                    class="paginationsecion fr mnop widthauto"
                    v-if="ptotal_page > 1"
                  >
                    <ul class="pagination mnop">
                      <li
                        class="page-item"
                        v-bind:class="{ disabled: pcurrent_page === 1 }"
                        v-show="pcurrent_page !== 1"
                      >
                        <a
                          class="page-link paginationbtn"
                          href="#"
                          @click.prevent="pagination2(pcurrent_page - 1)"
                          >Previous</a
                        >
                      </li>
                      <li
                        class="page-item"
                        v-bind:class="{ active: pcurrent_page == index + 1 }"
                        v-for="(i, index) in Number(ptotal_page)"
                        :key="index"
                      >
                        <a
                          class="page-link"
                          v-if="
                            i == 1 ||
                              i == 2 ||
                              i == 3 ||
                              i == 4 ||
                              i == 5 ||
                              i == 6 ||
                              i == 7 ||
                              i == 8 ||
                              i == ptotal_page ||
                              i == pcurrent_page ||
                              i == pcurrent_page - 1 ||
                              i == pcurrent_page + 1
                          "
                          href="#"
                          @click.prevent="pagination2(index + 1)"
                          >{{ index + 1 }}</a
                        ><a
                          class="page-link"
                          v-else
                          v-show="
                            (i == 9 && pcurrent_page > 9) ||
                              i == ptotal_page - 1
                          "
                          >...</a
                        >
                      </li>
                      <li
                        class="page-item"
                        v-bind:class="{
                          disabled: pcurrent_page == ptotal_page,
                        }"
                        v-show="pcurrent_page !== ptotal_page"
                      >
                        <a
                          class="page-link paginationbtn"
                          href="#"
                          @click.prevent="pagination2(pcurrent_page + 1)"
                          >Next</a
                        >
                      </li>
                    </ul>
                  </div>

                  <div class="cl"></div>
                  <transition-group
                    v-if="Object.keys(paymentreportList).length > 0"
                  >
                    <div
                      class="todolistbox"
                      v-for="(task, i) in paymentreportList"
                      :key="i"
                    >
                      <ul>
                        <li>
                          <label :for="task">
                            <input :id="task"  v-if="task.includes('Done')"  checked=true  type="checkbox"  :v-model="task">
                            <input :id="task"  v-else  type="checkbox"  :v-model="task">

                            <p  v-if="task.includes('Done')" v-html="task.slice(0,-7)"></p>
                            <p   v-else v-html="task"></p>

                          </label>
                        </li>
                      </ul>
                    </div>
                  </transition-group>
                  <p v-else>No schedule available for payment report!</p>
                </div>
              </div>
            </div>
          </div>

          <!-- upcoming commission distribution -->
          <div
            class="row"
            v-if="
              tab3 == 'Schedule Commission Distribution' &&
                navigation.module_name == 'Schedule Commission Distribution'
            "
          >
            <div class="col-md-12">
              <div class="dashboard-border">
                <div class="todolist">
                  <div class="todolist-title">
                    <label>
                      <button
                        type="button"
                        class="fr"
                        @click="AddCommissonLink()"
                      >
                        Add
                      </button>
                    </label>
                    <div
                      class="paginationsecion fr mnop widthauto"
                      v-if="ctotal_page > 1"
                    >
                      <ul class="pagination mnop">
                        <li
                          class="page-item"
                          v-bind:class="{ disabled: comcurrent_page === 1 }"
                          v-show="comcurrent_page !== 1"
                        >
                          <a
                            class="page-link paginationbtn"
                            href="#"
                            @click.prevent="pagination3(comcurrent_page - 1)"
                            >Previous</a
                          >
                        </li>
                        <li
                          class="page-item"
                          v-bind:class="{
                            active: comcurrent_page == index + 1,
                          }"
                          v-for="(i, index) in Number(ctotal_page)"
                          :key="index"
                        >
                          <a
                            class="page-link"
                            v-if="
                              i == 1 ||
                                i == 2 ||
                                i == 3 ||
                                i == 4 ||
                                i == 5 ||
                                i == 6 ||
                                i == 7 ||
                                i == 8 ||
                                i == ctotal_page ||
                                i == comcurrent_page ||
                                i == comcurrent_page - 1 ||
                                i == comcurrent_page + 1
                            "
                            href="#"
                            @click.prevent="pagination3(index + 1)"
                            >{{ index + 1 }}</a
                          ><a
                            class="page-link"
                            v-else
                            v-show="
                              (i == 9 && comcurrent_page > 9) ||
                                i == ctotal_page - 1
                            "
                            >...</a
                          >
                        </li>
                        <li
                          class="page-item"
                          v-bind:class="{
                            disabled: comcurrent_page == ctotal_page,
                          }"
                          v-show="comcurrent_page !== ctotal_page"
                        >
                          <a
                            class="page-link paginationbtn"
                            href="#"
                            @click.prevent="pagination3(comcurrent_page + 1)"
                            >Next</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="cl"></div>
                  <transition-group
                    v-if="Object.keys(commDistrList).length > 0"
                  >
                    <div
                      class="todolistbox"
                      v-for="(task, i) in commDistrList"
                      :key="i"
                    >
                      <ul>
                        <li>
                          <label :for="task">
                             <input :id="task"  v-if="task.includes('Done')" checked=true  type="checkbox"  placeholder="" :v-model="task">
                            <input :id="task"   v-else  type="checkbox"  placeholder="" :v-model="task">

                            <p  v-if="task.includes('Done')" v-html="task.slice(0,-7)"></p>
                            <p   v-else v-html="task"></p>
                             </label>
                        </li>
                      </ul>
                    </div>
                  </transition-group>
                  <p v-else>
                    No schedule available for commission distribution!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cl"></div>
      </div>
    </div>

    <!-- Confirmation Popup -->
      <div v-if="verifyPopup" style="position: absolute;" class="popupbox">
          <div class="exportbox memberrecharge">
          <div class="formtitle"> <div class="logo mr10"> <div class="iinnoicon-cog">  </div> </div>
              <div class="popuptitletext pl10">Verification Complete</div>
              <a class="fr popupclose handpointer" @click="verifyPopup=false">X</a></div>
              <div class="formpart">
                  <p class="textcenter"><strong>{{'Are you sure to complete this verification?'}}</strong></p>
                  <button type="button" class="fr mr10" @click="verifyPopup=false"> Cancel</button>
                  <button type="button" class="fr mr10" @click="completeVerification()">Yes</button>
              </div>
          </div>
          <div class="overlay"></div>
      </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      tab: "",  // vendor tab
      tab2: "", // payment tab
      tab3: "", // commission tab
      check1: "",
      error: "",
      noData: "",
      total_page: 0,
      ctotal_page: 0,
      ptotal_page: 0,
      current_page: 1, // vendor_current page
      pcurrent_page: 1, // payment_current page
      comcurrent_page: 1, // commission_currnet page
      addpopup: false,
      dispIcon: [],
      commDistrList: [], // commission array
      vendorDistrList: [], // vendor array
      paymentreportList: [], // payment array
      verifyPopup: false,

      navigationData: [
        {
          id: 1,
          module_type: "dashboardright",
          module_name: "Schedule Vendor Distribution",
          scheduleCount: 0,
        },
        {
          id: 2,
          module_type: "dashboardright",
          module_name: "Schedule Payment Report",
          scheduleCount: 0,
        },
        {
          id: 3,
          module_type: "dashboardright",
          module_name: "Schedule Commission Distribution",
          scheduleCount: 0,
        },
      ],
    };
  },
  mounted() {
    this.getCommDistList(this.comcurrent_page);
    this.getvendorList(this.current_page);
    this.getPaymentReportList(this.pcurrent_page);
  },
  methods: {
    // vendor tab
    TabChange(tab) {
      if (this.tab != tab) {
        this.tab = tab;
      } else {
        this.tab = "";
      }
    },
    // payment tab
    TabChange2(tab2) {
      if (this.tab2 != tab2) {
        this.tab2 = tab2;
      } else {
        this.tab2 = "";
      }
    },
    // commission tab
    TabChange3(tab3) {
      if (this.tab3 != tab3) {
        this.tab3 = tab3;
      } else {
        this.tab3 = "";
      }
    },

    // get upcoming commission distribution
    getCommDistList(page) {

      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/getupcomingschedule?page=` + page,
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.comcurrent_page = page;
            this.commDistrList = response.data.data.data;
            this.ctotal_page = response.data.data.last_page;
            if (response.data.pending_count > 0) {
              this.navigationData[2].scheduleCount = response.data.pending_count;
              this.TabChange3('Schedule Commission Distribution');
            }
          } else {
            this.commDistrList = [];
            this.noData = response.data.message;
            this.ctotal_page = 0;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data.status == 0) {
            this.error = error.response.data.message;
          }
        });
    },

    // get upcoming vendor report
    getvendorList(page) {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/getupcomingvendorreport?page=` + page,
        })
        .then((response) => {
          if (response.data.status === 1) {

            this.current_page = page;
            this.vendorDistrList = response.data.data.data;
            this.total_page = response.data.data.last_page;

            if(response.data.pending_count > 0)
            {
              this.navigationData[0].scheduleCount = response.data.pending_count;
              this.TabChange('Schedule Vendor Distribution');
            }

          } else {
            this.vendorDistrList = [];
            this.noData = response.data.message;
            this.total_page = 0;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data.status == 0) {
            this.error = error.response.data.message;
          }
        });
    },

    // get upcoming payment report
    getPaymentReportList(page) {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/getupcomingpaymentreport?page=` + page,
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.pcurrent_page = page;
            this.paymentreportList = response.data.data.data;
            this.ptotal_page = response.data.data.last_page;

            if(response.data.pending_count > 0 )
            {
              this.navigationData[1].scheduleCount = response.data.pending_count;
              this.TabChange2('Schedule Payment Report');
            }

          } else {
            this.paymentreportList = [];
            this.noData = response.data.message;
            this.ptotal_page = 0;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data.status == 0) {
            this.error = error.response.data.message;
          }
        });
    },

    // click 'Add commission option setup' event
    AddCommissonLink() {
      this.$router.push("/commission/adddistributionoption");
    },

    // vendor pagination
    pagination(next_page) {
      this.getvendorList(next_page);
      this.TabChange('');
    },

    // payment pagination
    pagination2(next_page) {
      this.getPaymentReportList(next_page);
       this.TabChange2('');
    },

    // commission pagination
    pagination3(next_page) {
      this.getCommDistList(next_page);
       this.TabChange3('');
    },

    //verify- confirmation - popup
    /*
    verifycheckbox()
    {
      this.verifyPopup = true;
    },

    completeVerification()
    {
      console.log('call verify API');
    }
    */

  },
};
</script>

<style scoped>
.countColor {
  color: red;
}
</style>
