<template>
    <div class="pagesection" id="leftsection">
    <div class="expandbox"  onclick="myFunction()"><div class="iinnoicon-menu_for_mobile"></div><div class="minushsign">-</div></div>
    <div class="headerbox">
        <div class="logo"><div class="iinnoicon-cog"></div></div>
        <div class="mainmenu" id="mainmenu">
            <div class="mobilemenubar">
                <a href="javascript:void(0);" class="icon" onclick="menuFunction()">
                <div class="iinnoicon-menu_for_mobile"></div>
              </a></div>
            <div class="mobilmenulink">
                <router-link to="/dashboard" title="Dashboard" :class="{'active':$route.name=='dashboard'}">Dashboard</router-link>
                <router-link to="/commission/distribution" title="Commission" :class="{'active':$route.name=='commission'}">Commission</router-link>
                <router-link to="/carrier" title="Carrier" :class="{'active':$route.name=='carrier'}">Carrier</router-link>
                <router-link to="/vendor" title="Vendor" :class="{'active':$route.name=='vendor'}">Vendor</router-link>
            </div>
        </div>
        <div class="header-rightbox">
        <ul>
          <!-- <li class="tooltipbox" v-for="(navigation,i) in this.$store.state.navigation" :key="i" v-show="navigation.module_name=='Tools'||navigation.module_name=='Setting'||navigation.module_name=='Calendar'">
          <router-link v-if="navigation.page_url!=null" :to="navigation.page_url" @click="$root.currentLeftMenu=i;"><div :class="navigation.icon_class"></div><span>{{navigation.module_name}}</span></router-link>
          <router-link v-else to="#"><div :class="navigation.icon_class"></div><span>{{navigation.module_name}}</span></router-link>
          </li> -->
          <li class="tooltipbox">
            <a href="javascript:;" @click="userBox" ><div class="iinnoicon-user_profile"></div></a><span>User Account</span>
            <transition name="slide">
            <div class="userbox" id="userbox" v-if="openuserbox" :class="{userboxshow:openuserbox}" v-click-outside="hidePopup">
              <ul>
                <li>
                  <div class="userimgbox">
                    <div class="iinnoicon-user_profile logoutimage"></div>
                      <div class="username">{{$store.state.ajax.org_user.name}}</div>
                       <div class="useremail"><a>{{$store.state.ajax.org_user.username}}</a></div>
                       <div class="userinfo">&nbsp;</div>
                       <div class="logoutlabel" @click.prevent="logout"><a href="login-username.html">Logout</a></div>
                      </div>
                    </li>
                 </ul>
              </div>
          </transition>
          </li>
        </ul>
      </div>
    </div>
    <div class="cl"></div>    
</div>
</template>
<script>
import vClickOutside from 'v-click-outside'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies);
export default {
    data() {
        return {
            openuserbox : ""
        }
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        /* Popup Box  */
        userBox() {
            this.openuserbox= !this.openuserbox;
        },

        /* Popup Hide  */
        hidePopup(){
            this.openuserbox=false;
        },

        /* Logout */
        logout() 
        {
            this.$store.dispatch("ajax", {
                method  : "get",
                url     : "api/userlogout",
            })
            .then(() => {
                VueCookies.set(window.location.hostname.substring(10, 4) + "accountcookie", "");
                VueCookies.set(window.location.hostname.substring(10, 4) + "accountlastLogin", "");
                // if (this.$store.state.ajax.org_user.loginType == 'associatelogin') {
                //     this.$router.push("/associate");
                // }
                if (this.$store.state.ajax.org_user.loginType == 'userlogin') {
                    this.$router.push("/user");
                }
                location.reload();
            });
        },
    }
}
</script>