export default {
    methods: {
        nameKeydown(e) {
            if (/[^a-z\s'A-Z0-9]/.test(e.key)) {
                e.preventDefault();
                this.$store.state.Messages.address = "Enter valid character";

            }
        },
        address2(e) {
            if (/[^a-z\s'A-Z0-9]/.test(e.key)) {
                e.preventDefault();
                this.$store.state.Messages.address2 = "Enter valid character"
            }
        },
        agencyname(e) {
            if (/[^a-z\s'-]/gi.test(e.key)) {
                e.preventDefault();
                return this.$store.state.Messages.invalid = "Enter valid character"
            }
        },
        agencyLastName(e) {
            if (/[^a-z\s'-]/gi.test(e.key)) {
                e.preventDefault();
                return this.$store.state.Messages.Lastname = "Enter valid character"
            }
        },

        removeValidation() {
            if (this.$store.state.Messages.address) { this.$store.state.Messages.address = ''; } else if (this.$store.state.Messages.address2) { this.$store.state.Messages.address2 = ''; }
            if (this.$store.state.Messages.invalid) { this.$store.state.Messages.invalid = ''; }
            if (this.$store.state.Messages.Lastname) { this.$store.state.Messages.Lastname = ''; }
        },
    }
};