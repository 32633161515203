<template> 
    <div class="loginboxpart">
    <div class="loginform">
        <div class="loginlog">
            <h1 class="titleFont"> Account Report </h1>
            <img src="/images/icons/logo2.svg" alt="gigly">
            <!-- <div class="iinnoicon-cog"></div> -->
            <div class="cl"></div>
            <span>{{$store.state.Messages.heading}}</span>
        </div>
        <div class="cl"></div>
        <div class="login_formbox login">
          <transition name="slide-fade">
          <div class="warning" v-if="sucess!==''">
            <div class="positive-error">
              <div class="crossbtn" @click="sucess=''">X</div>
              <p>{{sucess}}</p>
            </div>
          </div>
           <div class="warning" v-if="errormessage!==''">
            <div class="negative-error">
              <div class="crossbtn" @click="errormessage=''">X</div>
              <p>{{errormessage}}</p>
            </div>
          </div>
          <div class="warning" v-if="tokenmessage!==''">
            <div class="negative-error">
              <div class="crossbtn" @click="tokenmessage=''">X</div>
              <p>{{tokenmessage}}</p>
            </div>
          </div>
          </transition>
          <form @submit.prevent="checkpassword">
            <div class="row">
              <div class="col-md-12">
                    <div class="form-group">
                        <label>Username: {{this.$store.state.Login.UserData.username}}</label>                        
                    </div>
                </div>             
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Password</label>
                        <input type="password" placeholder="Enter Your Password" v-model="password" ref="email" 
                        @input="errors.password ? errors.password=null : '' "
                        :class="{ 'is-invalid':submitted && $v.password.$error || errors.password}"
                         class="form-control"/>
                        <div v-if="submitted && $v.password.$error && !errors.password" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                        <div v-if="errors.password " class="invalid-feedback">{{errors.password[0]}}</div>
                        <div class="cl"></div>
                    </div>
                </div>
                <div class="cl"></div>
                <div class="col-md-12">
                    <div class="loginbtnpart">
                        <button class="g-recaptcha" 
                          data-sitekey="6LcxGZ0aAAAAAItsQ3uKzfzNrR6ZlmOwuR6Wpvac" 
                          type="submit" 
                          data-action='submit'>Login</button>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="bottom mt15">                       
                        <!-- <p class="forget-pass mt20" v-if="this.$route.name=='userlogin'">
                            <router-link to="/forgotpassword">Forgot password</router-link>
                        </p> -->
                    </div>
                </div>
                <div class="cl"></div>
            </div>
         </form>
        </div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import jwt from "jsonwebtoken";
import VueCookies from 'vue-cookies';
import { required} from "vuelidate/lib/validators";
import { load } from 'recaptcha-v3';
// load('6LcxGZ0aAAAAAItsQ3uKzfzNrR6ZlmOwuR6Wpvac', {
//   useRecaptchaNet: true,
//   autoHideBadge: true
// })
export default {
  components:
  {
    // VueRecaptcha
  },
  data() {
    return {
      errors: "",
      sucess:"",
      errormessage:"",    
      password:"", 
      submitted:false,
      tokenmessage:"",
      robot: false,
     };
  },
   validations: {
    password: { required,},    
  },
   methods: { 

    async  asyncFunction() {
      if(window.location.href.indexOf("https://gig.gigly.com/user")>=0)
      {
        const recaptcha = await load('6LfBodQaAAAAACgEkXrYXawh-ryD6Gl0H1xu_PvM', {
          useRecaptchaNet: true,
          autoHideBadge: true
        })
        this.token = await recaptcha.execute('login')
      }
      else
      {
        const recaptcha = await load('6LcxGZ0aAAAAAItsQ3uKzfzNrR6ZlmOwuR6Wpvac', {
          useRecaptchaNet: true,
          autoHideBadge: true
        })
        this.token = await recaptcha.execute('login')
        // console.log(this.token)
      }
    },

     autoHide()
    {
      setTimeout(() => this.errormessage = "", 5000);
      setTimeout(() => this.tokenmessage = "", 5000);
    },

    checkpassword() {
      // e.preventDefault();
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } 
      var usernameApi='';
      if (this.$route.name == 'userlogin') {
          usernameApi = "api/checklogin";
      }
      if (this.$route.name == 'associatelogin') {
          usernameApi = "api/associatelogin";
      }    
      this.$store.dispatch("ajax", {
          method: "post",
          loader:'page',
          url:usernameApi,
          params: {
            username: this.$store.state.Login.UserData.username,
            password: this.password,
            token: this.$store.state.Login.UserData.token,            
          },
        })
        .then((response) => {        
          if (response.data.status === 1) {       
            // VueCookies.set(window.location.hostname.substring(10, 4) + "cookies",jwt.sign({iss:'in002',iat:1611910895,exp: Math.floor(Date.now() / 1000) + (60*60*12),nbf:1611910895,jti:'oOgLRFEfRGgsp4yr',sub:1,prv:'87e0af1ef9fd15812fdec97153a14e0b047546aa',logindata:response.data.data}, this.$store.state.ajax.keys, { algorithm: 'HS256'}),'12h','/','',true,'Lax');
            var data = {};
            data = response.data.data;
            VueCookies.set(window.location.hostname.substring(10, 4) + "accountcookie",jwt.sign({iss:'in002',iat:1611910895,exp: Math.floor(Date.now() / 1000) + (60*60*12),nbf:1611910895,jti:'oOgLRFEfRGgsp4yr',sub:1,prv:'87e0af1ef9fd15812fdec97153a14e0b047546aa',logindata:data}, this.$store.state.ajax.keys, { algorithm: 'HS256'}),'12h');
            this.$store.state.ajax.org_user=data;
            if (response.data.data.access_token!='') 
            {
              this.$store.state.ajax.org_user.access_token = response.data.data.access_token;
              this.$store.state.Login.emailUser=response.data.data.username;
              this.sucess=response.data.message;
              this.$router.push('/dashboard');
            }        
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {         
            if (error.response.data.code==401) {
             this.errormessage=error.response.data.message;
             this.autoHide();
            }
            else if (error.response.data.code==400){
              this.tokenmessage =error.response.data.message;
              this.autoHide();
            }
            if(error.response.data.data) {           
            this.errors = error.response.data.data;
            }       
          }
        });      
    },
    focusInput() {
      this.$refs.email.focus();
    }
  },
  mounted(){
   this.focusInput();
   this.asyncFunction();
  }
};
</script>
<style scoped>
.titleFont{
  color:#0078A8;
}
</style>

